import moment from "moment";

export function formatDate(date) {
  const fd = moment(date);
  return fd.format("DD-MMM-YYYY");
}

// https://www.freecodecamp.org/news/three-ways-to-title-case-a-sentence-in-javascript-676a9175eb27/
export function titleCase(str) {
  return str
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}

export function msToHours(ms) {
  return ms / 1000.0 / 60.0 / 60.0;
}

export function unixToDate(timestamp) {
  const date = new Date(timestamp);
  const hours = date.getHours();
  const minutes = "0" + date.getMinutes();
  const seconds = "0" + date.getSeconds();
  const formattedTime =
    hours + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);
  return formattedTime;
}

export function numFormatComma(num) {
  return num.toLocaleString("en-US");
}
