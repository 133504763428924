import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueGtag from "vue-gtag";
import VueMixpanelBrowser from "vue-mixpanel-browser";

Vue.use(VueMixpanelBrowser, {
  token: process.env.VUE_APP_MIXPANEL_TOKEN,
  config: { debug: true, track_pageview: true },
});

Vue.use(
  VueGtag,
  {
    appName: "tracelight-ui",
    pageTrackerScreenviewEnabled: true,
    config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS },
  },
  router
);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
