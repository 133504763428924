import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import EmailHost from "@/components/icons/emailHost.vue";
import EmailDomain from "@/components/icons/emailDomain.vue";
Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: "#58abe7",
        secondary: "#112748",
        accent: "#E86051",
        info: "#506078",
      },
    },
  },
  icons: {
    values: {
      emailhost: {
        component: EmailHost,
      },
      emaildomain: {
        component: EmailDomain,
      },
    },
  },
});
