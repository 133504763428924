import Vue from "vue";

import {
  darkreconTrends,
  darkreconSearchGet,
  darkreconStatsGet,
  darkreconStatsSourcesGet,
  darkreconSearchStatsGet,
  darkreconSearchExpandedGet,
  darkreconConversationsConversationidGet,
  darkreconMessagesMessageidScreenshotsObfGet,
  darkreconAuthorsAuthoridGet,
  darkreconAuthorsAuthoridConversationsGet,
  darkreconConversationsConversationidMessagesGet,
} from "../../clients/tracelight";
import EventBus from "../../clients/event-bus";
import moment from "moment";
import router from "../../router";
import { setPermalinkParams } from "@/clients/tracelight";
/* function highlight(text) {
  const highlightSpan = '<span style="color: #e86051">';
  text = text.replaceAll("<strong>", highlightSpan);
  text = text.replaceAll("</strong>", "</span>");
  return text;
} */

const darkRecon = {
  namespaced: true,
  state: {
    permalinkPage: 1,
    queryString: "",
    queryStringDashboard: "",
    queryStringTrends1: "",
    queryStringTrends2: "",
    sortByDashboard: "latest_message",
    darkReconSources: [],
    darkReconTrends: {
      authors: {
        term1: [],
        term2: [],
      },
      messages: [],
      sources: {
        term1: [],
        term2: [],
      },
      terms: {
        term1: "",
        term2: "",
      },
    },
    conversation: undefined,
    date_start: null,
    date_end: null,
    sortBy: "relevance",
    sortOrder: "desc",
    sortOrderAuthorProfile: "desc",
    authorProfilePage: 1,
    darkReconPage: 1,
    darkReconLoading: false,
    authorProfileLoading: false,
    stopLoadingAuthorMessages: false,
    stopLoadingDarkReconConversations: false,
    permalinkConversation: [],
    messages: [],
    authorOptions: [],
    networkOptions: [],
    sourceOptions: [],
    stats: [],
    screenshotObf: [],
    screenshotRaw: [],
    searchStats: {
      num_conversations: 0,
      num_messages: 0,
      num_sources: 0,
      num_authors: 0,
    },
    darkReconTotals: {
      num_conversations: 0,
      num_messages: 0,
      num_sources: 0,
      num_authors: 0,
      num_networks: 0,
    },
    threatActorProfile: [],
    threatActorMessages: [],
    threatActorAllMessages: [],
  },
  mutations: {
    clearConversation(state) {
      state.conversation = [];
      state.messages = [];
      state.stats = {};
      state.screenshotObf = [];
      state.screenshotRaw = [];
      state.searchStats = {
        num_conversations: 0,
        num_messages: 0,
        num_sources: 0,
        num_authors: 0,
      };
      state.darkReconTotals = {
        num_conversations: 0,
        num_messages: 0,
        num_sources: 0,
        num_authors: 0,
        num_networks: 0,
      };
      state.authorOptions = [];
      state.networkOptions = [];
      state.sourceOptions = [];
      state.queryString = "";
      state.dates = [
        moment().subtract(365, "days").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ];
      state.sortBy = "relevance";
      state.sortOrder = "desc";
      state.permalinkConversation = [];
      state.threatActorProfile = [];
      state.threatActorMessages = [];
      state.sortOrderAuthorProfile = "desc";
      state.authorProfilePage = 1;
      state.darkReconPage = 1;
      state.stopLoadingAuthorMessages = false;
      state.threatActorAllMessages = [];
      state.stopLoadingDarkReconConversations = false;
      state.darkReconLoading = false;
      state.authorProfileLoading = false;
    },
    nextPageAuthorProfile(state) {
      state.authorProfilePage += 1;
    },
    nextPageDarkRecon(state) {
      state.darkReconPage += 1;
    },
    replaceValue(state, { key, value }) {
      Vue.set(state, key, value);
    },
    fillMessages(state, payload) {
      state.messages.push(payload);
      for (let x = 0; x < state.conversation.length; x++) {
        let convo = state.conversation[x];
        if (convo.conversation_id == payload.conversation_id) {
          convo.messageLoaded = true;
        }
      }
    },
    fillInitialPermalinkMsg(state, payload) {
      state.permalinkConversation[0].message = payload;
    },
    fillPermalinkMessages(state, payload) {
      state.permalinkConversation[0].messages =
        state.permalinkConversation[0].messages.concat(payload);
    },
    fillStats(state, payload) {
      state.stats = payload;
    },
    fillScreenshotDataObj(state, payload) {
      state.screenshotObf = payload;
    },
    fillScreenshotDataRaw(state, payload) {
      state.screenshotRaw = payload;
    },
    fillPermalinkConversation(state, payload) {
      state.permalinkConversation.push(payload);
      state.permalinkConversation[0].messages = [];
      state.permalinkConversation[0].message = {};
    },
    fillAuthorProfile(state, payload) {
      state.threatActorProfile = payload;
    },
    fillAuthorMessages(state, payload) {
      state.threatActorMessages = payload;
      for (let x = 0; x < state.threatActorMessages.length; x++) {
        let convo = state.threatActorMessages[x];
        convo.messages = [];
        convo.messages.push(convo.message);
      }
    },
    allAuthorMessages(state, payload) {
      state.threatActorMessages.push(payload);
    },
    stoppingAuthorMessages(state, payload) {
      state.stopLoadingAuthorMessages = true;
    },
    stoppingConversationMessages(state, payload) {
      state.stopLoadingDarkReconConversations = true;
    },
    fillAdditionalConversations(state, payload) {
      state.conversation.push(payload);
    },
  },
  actions: {
    addAuthorProfileMessages(context, authorProfile) {
      context.commit("replaceValue", {
        key: "authorProfileLoading",
        value: true,
      });
      context.commit("nextPageAuthorProfile");
      return new Promise((resolve, reject) => {
        const payload = {};
        const params = {
          network_id_ext: authorProfile[0],
          source_id_ext: authorProfile[1],
          author_id_ext: authorProfile[2],
          sort_order: context.state.sortOrderAuthorProfile,
          sort_by: "date",
          page: context.state.authorProfilePage,
          results_per_page: 20,
        };
        darkreconAuthorsAuthoridConversationsGet(payload, {
          author_id: authorProfile[2],
          sort_order: context.state.sortOrderAuthorProfile,
          sort_by: "date",
          page: context.state.authorProfilePage,
          results_per_page: 20,
        })
          .then(function (result) {
            console.log("LOADING", result);
            if (result.data.data.length > 0) {
              const allMessages = context.state.threatActorMessages.concat(
                result.data.data
              );
              context.commit("replaceValue", {
                key: "threatActorMessages",
                value: allMessages,
              });
              context.commit("replaceValue", {
                key: "authorProfileLoading",
                value: false,
              });
              resolve(result.data.data);
            } else {
              context.commit("stoppingAuthorMessages", true);
              context.commit("replaceValue", {
                key: "authorProfileLoading",
                value: false,
              });
              context.commit("replaceValue", {
                key: "authorProfilePage",
                value: 1,
              });
              resolve(result.data.data);
            }
          })
          .catch(function (error) {
            console.debug(error);
            return;
          });
      });
    },
    addConversations(context) {
      context.commit("replaceValue", { key: "darkReconLoading", value: true });
      context.commit("nextPageDarkRecon");
      return new Promise((resolve, reject) => {
        const payload = {};
        const params = {
          q: context.state.queryString,
          sort_by: context.state.sortBy,
          sort_order: context.state.sortOrder,
          date_start: context.state.date_start,
          date_end: context.state.date_end,
          page: context.state.darkReconPage,
          results_per_page: 20,
        };
        darkreconSearchGet(payload, params)
          .then(function (result) {
            if (result.data.data.length > 0) {
              for (let convo of result.data.data) {
                if (Array.isArray(convo.title)) {
                  //convo.title = highlight(convo.title[0]);
                } else {
                  //convo.title = highlight(convo.title);
                }

                if (
                  convo.messages.filter((r) => r.most_relevant == true)
                    .length == 1
                ) {
                  convo.message = convo.messages.filter(
                    (r) => r.most_relevant == true
                  )[0];
                } else {
                  convo.message = convo.messages[0];
                }

                for (let msg of convo.messages) {
                  context.state.authorOptions.push(msg.author.id);
                  msg.conversation_id = convo.conversation_id;
                  msg.body.forEach((b) => {
                    if (b.content != null) {
                      //b.content = highlight(b.content);
                    }
                  });
                }

                context.state.networkOptions.push(convo.network.id);
                context.state.sourceOptions.push(convo.source.id);
                context.state.authorOptions = [
                  ...new Set(context.state.authorOptions),
                ];
                context.state.networkOptions = [
                  ...new Set(context.state.networkOptions),
                ];
                context.state.sourceOptions = [
                  ...new Set(context.state.sourceOptions),
                ];

                context.commit("fillAdditionalConversations", convo);
                context.commit("replaceValue", {
                  key: "darkReconLoading",
                  value: false,
                });
                resolve(result.data.data);
              }
            } else {
              context.commit("stoppingConversationMessages", true);
              context.commit("replaceValue", {
                key: "darkReconLoading",
                value: false,
              });
            }
          })
          .catch(function (error) {
            console.debug(error);

            return;
          });
      });
    },
    updateAuthorMsgs(context, authorProfile) {
      const payload = {};
      const params = {
        network_id_ext: authorProfile[0],
        source_id_ext: authorProfile[1],
        author_id_ext: authorProfile[2],
        conversation_id: authorProfile[3],
        //message_id: authorProfile[4],
        sort_order: context.state.sortOrderAuthorProfile,
        page: context.state.authorProfilePage,
        results_per_page: 20,
      };
      //darkreconNetworksNetworkIdExtSourcesSourceIdExtAuthorsAuthorIdExtSearchExpandedGet(payload,params)
      darkreconAuthorsAuthoridConversationsGet(payload, {
        author_id: authorProfile[2],
      })
        .then(function (result) {
          console.log(result.data.data);
          for (let x = 0; x < context.state.threatActorMessages.length; x++) {
            let convo = context.state.threatActorMessages[x];
            if (convo.conversation_id == result.data.data.conversation_id) {
              convo.total_messages = result.data.data.total_messages;
            }
          }
          context.commit("allAuthorMessages", result.data.data);
        })
        .catch(function (error) {
          console.debug(error);
          return;
        });
    },
    getAuthorProfile(context, authorProfile) {
      context.state.authorProfilePage = 1;
      const payload = {};
      const params = {
        network_id_ext: authorProfile[0],
        source_id_ext: authorProfile[1],
        author_id_ext: authorProfile[2],
        sort_order: context.state.sortOrderAuthorProfile,
        sort_by: "date",
        page: context.state.authorProfilePage,
        results_per_page: 20,
      };

      darkreconAuthorsAuthoridGet(payload, {
        author_id: authorProfile[2],
      })
        .then(function (result) {
          console.log(result.data.data);
          context.commit("fillAuthorProfile", result.data.data);
        })
        .catch(function (error) {
          console.debug(error);
          return;
        });

      darkreconAuthorsAuthoridConversationsGet(payload, {
        author_id: authorProfile[2],
        sort_order: context.state.sortOrderAuthorProfile,
        sort_by: "date",
        page: context.state.authorProfilePage,
        results_per_page: 20,
      })
        .then(function (result) {
          console.log(result);
          if (result.data.data.length > 0) {
            context.commit("fillAuthorMessages", result.data.data);
          }
        })
        .catch(function (error) {
          console.debug(error);
          return;
        });
    },
    getTotals(context) {
      return new Promise((resolve) => {
        const params = {
          start_date: "1970-01-01",
          end_date: "2025-01-01",
        };
        darkreconStatsGet(params, params, params).then(function (result) {
          context.state.darkReconTotals = result.data.data;
          console.log(context.state.darkReconTotals);
          resolve(result.data.data);
        });
      });
    },

    trendsSearch(context, { key, value }) {
      return new Promise((resolve) => {
        context.commit("replaceValue", {
          key: "darkReconTrends",
          value: {
            authors: {
              term1: [],
              term2: [],
            },
            messages: [{}],
            sources: {
              term1: [],
              term2: [],
            },
            terms: {
              term1: "",
              term2: "",
            },
          },
        });
        context.commit("replaceValue", {
          key,
          value,
        });
        const payload = {};
        const params = {
          term1: context.state.queryStringTrends1,
        };

        if (
          context.state.queryStringTrends2 !== "" &&
          context.state.queryStringTrends2 !== null
        ) {
          params["term2"] = context.state.queryStringTrends2;
        }

        darkreconTrends(payload, params).then(function (result) {
          context.commit("replaceValue", {
            key: "darkReconTrends",
            value: result.data.data,
          });
          resolve(result.data.data);
        });
      });
    },

    dashboardSearch(context, { key, value }) {
      return new Promise((resolve) => {
        context.commit("replaceValue", {
          key: "darkReconSources",
          value: [],
        });
        context.commit("replaceValue", {
          key,
          value,
        });
        const payload = {};
        const params = {
          source_name: context.state.queryStringDashboard,
          sort_by: context.state.sortByDashboard,
          sort_order: "desc",
        };

        darkreconStatsSourcesGet(payload, params).then(function (result) {
          context.commit("replaceValue", {
            key: "darkReconSources",
            value: result.data.data,
          });
          resolve(result.data.data);
        });
      });
    },
    update(context, { key, value }) {
      return new Promise((resolve) => {
        context.commit("replaceValue", {
          key,
          value,
        });
        context.state.conversation = [];
        context.state.darkReconPage = 1;
        const payload = {};

        const params = {
          q: context.state.queryString,
          sort_by: context.state.sortBy,
          sort_order: context.state.sortOrder,
          page: context.state.darkReconPage,
          results_per_page: 20,
        };

        if (context.state.date_start) {
          params.date_start = context.state.date_start;
        } else {
          params.date_start = null;
        }
        if (context.state.date_end) {
          params.date_end = context.state.date_end;
        } else {
          params.date_end = null;
        }

        setPermalinkParams(params);
        if (context.state.queryString.length > 0) {
          setPermalinkParams(params);

          darkreconSearchStatsGet(payload, params)
            .then(function (result) {
              console.warn(result);
              context.commit("fillStats", result.data.data);
              context.state.searchStats = result.data.data;
            })
            .catch(function (error) {
              console.debug(error);
              return;
            });
          darkreconSearchGet(payload, params)
            .then(function (result) {
              console.warn(result);
              context.state.authorOptions = [];
              context.state.networkOptions = [];
              context.state.sourceOptions = [];
              if (result.data.data.length > 0) {
                for (let convo of result.data.data) {
                  if (Array.isArray(convo.title)) {
                    //convo.title = highlight(convo.title[0]);
                  } else {
                    //convo.title = highlight(convo.title);
                  }

                  if (
                    convo.messages.filter((r) => r.most_relevant == true)
                      .length == 1
                  ) {
                    convo.message = convo.messages.filter(
                      (r) => r.most_relevant == true
                    )[0];
                  } else {
                    convo.message = convo.messages[0];
                  }
                  for (let msg of convo.messages) {
                    context.state.authorOptions.push(msg.author.id);
                    msg.conversation_id = convo.conversation_id;
                    msg.body.forEach((b) => {
                      if (b.content != null) {
                        //b.content = highlight(b.content);
                      }
                    });
                  }
                  context.state.networkOptions.push(convo.network.id);
                  context.state.sourceOptions.push(convo.source.id);
                  context.state.authorOptions = [
                    ...new Set(context.state.authorOptions),
                  ];
                  context.state.networkOptions = [
                    ...new Set(context.state.networkOptions),
                  ];
                  context.state.sourceOptions = [
                    ...new Set(context.state.sourceOptions),
                  ];
                }
              }
              context.commit("replaceValue", {
                key: "conversation",
                value: result.data.data,
              });
              resolve(result.data.data);
              for (let x = 0; x < context.state.conversation.length; x++) {
                let convo = context.state.conversation[x];
                convo.messageLoaded = false;
              }
              EventBus.$emit("DATA_LOADING", false);
            })
            .catch(function (error) {
              console.debug(error);
              EventBus.$emit("DATA_LOADING", false);
              return;
            });
        }
      });
    },
    loadConversationPermalinkMessages(context, permalinkConversation) {
      context.commit("replaceValue", {
        key: "permalinkPage",
        value: permalinkConversation[1],
      });
      const payload = {};
      return new Promise((resolve) => {
        const params = {
          conversation_id: permalinkConversation[0],
          page: context.state.permalinkPage,
          results_per_page: 20,
        };
        darkreconConversationsConversationidMessagesGet(payload, params)
          .then(function (result) {
            console.log(result);
            if (result.data.data) {
              for (let msg of result.data.data) {
                context.state.authorOptions.push(msg.author.id);
                msg.conversation_id = result.data.data.conversation_id;
                /*     msg.body.forEach((b) => {
                    if (b.content != null) {
                      b.content = highlight(b.content);
                    }
                  }); */
                context.commit("fillPermalinkMessages", msg);
              }
              resolve(result.data.data);
            } else {
              resolve([]);
            }
          })
          .catch(function (error) {
            console.debug(error);
            return;
          });
      });
    },
    addPermalinkConversation(context, permalinkConversation) {
      context.commit("replaceValue", { key: "permalinkPage", value: 1 });
      const payload = {};

      if (permalinkConversation.length == 1) {
        return new Promise((resolve) => {
          const params = {
            conversation_id: permalinkConversation[0],
            page: context.state.permalinkPage,
            results_per_page: 20,
          };

          darkreconConversationsConversationidGet(payload, params)
            .then(function (result) {
              console.log(result.data);
              if (result.data.data) {
                context.commit("fillPermalinkConversation", result.data.data);
              }
            })
            .catch(function (error) {
              console.debug(error);
              return;
            });
          console.log(params);
          darkreconConversationsConversationidMessagesGet(payload, {
            conversation_id: permalinkConversation[0],
            page: context.state.permalinkPage,
            results_per_page: 20,
          })
            .then(function (result) {
              console.log(result.data);
              if (result.data.data) {
                /*    if (Array.isArray(result.data.data.title)) {
                  result.data.data.title = highlight(result.data.data.title[0]);
                } else {
                  result.data.data.title = highlight(result.data.data.title);
                } */

                if (
                  result.data.data.filter((r) => r.most_relevant == true)
                    .length == 1
                ) {
                  result.data.data.message = result.data.data.filter(
                    (r) => r.most_relevant == true
                  )[0];
                } else {
                  result.data.data.message = result.data.data[0];
                }
                for (let msg of result.data.data) {
                  context.state.authorOptions.push(msg.author.id);
                  msg.conversation_id = result.data.data.conversation_id;
                  /*     msg.body.forEach((b) => {
                    if (b.content != null) {
                      b.content = highlight(b.content);
                    }
                  }); */
                }
                context.commit("fillPermalinkMessages", result.data.data);
                context.commit("fillInitialPermalinkMsg", result.data.data[0]);
                /*  context.state.permalinkConversation[0].message.author =
                  result.data.data[0].author; */
                resolve(result.data.data);
              }
            })
            .catch(function (error) {
              console.debug(error);
              return;
            });
        });
      } else {
        return new Promise((resolve) => {
          const params = {
            conversation_id: permalinkConversation[0],
            message_id: permalinkConversation[1],
          };
          darkreconConversationsConversationidGet(payload, params)
            .then(function (result) {
              console.warn(result.data);
              if (result.data.data) {
                context.commit("fillPermalinkConversation", result.data.data);
                resolve(result.data.data);
              }
            })
            .catch(function (error) {
              console.debug(error);
              return;
            });
          darkreconConversationsConversationidMessagesGet(payload, {
            conversation_id: permalinkConversation[0],
            message_id: permalinkConversation[1],
          })
            .then(function (result) {
              console.warn(result);
              if (result.data.data.length > 0) {
                /*    if (Array.isArray(result.data.data.title)) {
                  result.data.data.title = highlight(result.data.data.title[0]);
                } else {
                  result.data.data.title = highlight(result.data.data.title);
                } */

                for (let msg of result.data.data) {
                  if (msg.most_relevant == true) {
                    result.data.data.message = msg;
                  } else {
                    result.data.data.message = result.data.data[0];
                  }
                  context.state.authorOptions.push(msg.author.id);
                  msg.conversation_id = result.data.data.conversation_id;
                  /*     msg.body.forEach((b) => {
                    if (b.content != null) {
                      b.content = highlight(b.content);
                    }
                  }); */
                }
                context.commit("fillPermalinkMessages", result.data.data);
                context.commit("fillInitialPermalinkMsg", result.data.data[0]);
                resolve(result.data.data);
              }
            })
            .catch(function (error) {
              console.debug(error);
              return;
            });
        });
      }
    },
    addMessages(context, conversation) {
      const payload = {};
      const params = {
        //q: context.state.queryString,
        //message_id: conversation.message.id,
        //source_id: conversation.source.id,
        //network_id: conversation.network.id,
        start: conversation.message.id,
        conversation_id: conversation.conversation_id,
      };
      darkreconSearchExpandedGet(payload, params)
        .then(function (result) {
          console.warn(result);
          for (let x = 0; x < context.state.conversation.length; x++) {
            let convo = context.state.conversation[x];
            if (convo.conversation_id == result.data.data.conversation_id) {
              convo.total_messages = result.data.data.total_messages;
            }
          }
          for (let msg of result.data.data.messages) {
            msg.conversation_id = result.data.data.conversation_id;
            msg.body.forEach((b) => {
              if (b.content != null) {
                //b.content = highlight(b.content);
              }
            });
          }
          console.warn(result.data.data);
          if (
            result.data.data.conversation_id == null ||
            result.data.data.conversation_id == undefined
          ) {
            result.data.data.conversation_id = conversation.conversation_id;
          }

          context.commit("fillMessages", result.data.data);
        })
        .catch(function (error) {
          console.debug(error);
          return;
        });
    },
    addScreenshots(context, screenshots) {
      const payload = {};
      const params = {
        message_id: screenshots[1],
      };
      darkreconMessagesMessageidScreenshotsObfGet(payload, params)
        .then(function (result) {
          console.log(result);
          context.commit("fillScreenshotDataObj", result.data.data.screenshots);
        })
        .catch(function (error) {
          console.debug(error);
          return;
        });
    },
  },
};
export default darkRecon;
