import {
  listScrapingResults,
  listUsers,
  pluginDataIdDownloadGet,
  setPermalinkParams,
} from "@/clients/tracelight";

const scrapingResult = {
  namespaced: true,
  state: {
    results: [],
    users: [],
    pngFiles: [],
    htmlFiles: [],
    page: 1,
    created_after: null,
    created_before: null,
    scraped_by: null,
    searchTerm: "",
  },
  actions: {
    loadMoreResults(context) {
      context.commit("nextPage");

      return new Promise((resolve) => {
        const params = {
          results_per_page: 20,
          page: context.state.page,
          created_after: context.state.created_after,
          created_before: context.state.created_before,
          sort_by: "created_at",
          sort_order: "asc",
          scraped_by: context.state.scraped_by,
        };
        const payload = {};

        if (context.state.searchTerm.length > 0) {
          params.q = context.state.searchTerm;
        } else {
          params.q = null;
        }

        if (context.state.scraped_by == "All") {
          params.scraped_by = null;
        }

        listScrapingResults(payload, params)
          .then(function (result) {
            const allResults = context.state.results.concat(result.data.data);
            context.commit("replaceValue", {
              key: "results",
              value: allResults,
            });
            resolve(result.data.data);
          })
          .catch(function (error) {
            console.error(error);
          });
      });
    },
    getUsers(context) {
      const payload = {};
      const params = { results_per_page: 3000 };
      listUsers(payload, params)
        .then(function (result) {
          context.commit("replaceValue", {
            key: "users",
            value: result.data.data,
          });
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    downloadImages(context, id) {
      return new Promise((resolve) => {
        const payload = {};
        pluginDataIdDownloadGet(payload, {
          scrape_id: id,
          results_per_page: 3000,
        })
          .then((result) => {
            resolve(result.data);
            if (result.data.download_urls) {
              let pngFiles = [];
              let htmlFiles = [];
              for (let d = 0; d < result.data.download_urls.png.length; d++) {
                let png = result.data.download_urls.png[d];

                pngFiles.push(png);
              }
              for (let d = 0; d < result.data.download_urls.html.length; d++) {
                let html = result.data.download_urls.html[d];

                htmlFiles.push(html);
              }
              context.commit("replaceValue", {
                key: "pngFiles",
                value: pngFiles,
              });
              context.commit("replaceValue", {
                key: "htmlFiles",
                value: htmlFiles,
              });
            }
          })
          .catch(function (error) {
            console.error(error);
            return;
          });
      });
    },
    getResults(context) {
      return new Promise((resolve) => {
        const payload = {};
        let params = {
          results_per_page: 20,
          page: context.state.page,
          created_after: context.state.created_after,
          created_before: context.state.created_before,
          sort_by: "created_at",
          sort_order: "asc",
          scraped_by: context.state.scraped_by,
        };

        if (context.state.searchTerm.length > 0) {
          params.q = context.state.searchTerm;
        } else {
          params.q = null;
        }

        if (context.state.scraped_by == "All") {
          params.scraped_by = null;
        }

        setPermalinkParams(params);

        for (const param of Object.keys(params)) {
          if (params[param] == "null" || params[param] == null) {
            delete params[param];
          }
        }

        listScrapingResults(payload, params)
          .then(function (result) {
            context.commit("replaceValue", {
              key: "results",
              value: result.data.data,
            });
            resolve(result.data.data);
          })
          .catch(function (error) {
            console.error(error);
            alert(error);
          });
      });
    },
  },
  mutations: {
    clearState(state) {
      state.results = [];
      state.users = [];
      state.page = 1;
    },
    replaceValue(state, { key, value }) {
      state[key] = value;
    },
    nextPage(state) {
      state.page += 1;
    },
  },
};

export default scrapingResult;
