import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index";

const DarkReconForumManager = () =>
  import(
    /* webpackChunkName: "DarkReconForumManager" */ "@/views/DarkReconForumManager.vue"
  );

const DarkReconSiteManagerCredentials = () =>
  import(
    /* webpackChunkName: "DarkReconSiteManagerCredentials" */ "@/views/DarkReconSiteManagerCredentials.vue"
  );

const DarkReconSiteManager = () =>
  import(
    /* webpackChunkName: "DarkReconSiteManager" */ "@/views/DarkReconSiteManager.vue"
  );
const Advisory = () =>
  import(/* webpackChunkName: "Advisory" */ "@/views/Advisory.vue");
const BreachDashboard = () =>
  import(
    /* webpackChunkName: "BreachDashboard" */ "@/views/BreachDashboard/index.vue"
  );
const BreachManagement = () =>
  import(
    /* webpackChunkName: "BreachManagement" */ "@/views/BreachManagement/index.vue"
  );
const BreachPermalink = () =>
  import(
    /* webpackChunkName: "BreachPermalink" */ "@/views/BreachPermalink/index.vue"
  );
const BreachResearch = () =>
  import(
    /* webpackChunkName: "BreachResearch" */ "@/views/BreachResearch/index.vue"
  );
const ClientIdentity = () =>
  import(
    /* webpackChunkName: "ClientIdentity" */ "@/views/ClientIdentity/index.vue"
  );
const DarkReconScreenshots = () =>
  import(
    /* webpackChunkName: "DarkReconScreenshots" */ "@/views/DarkReconScreenshots.vue"
  );
const DarkRecon = () =>
  import(/* webpackChunkName: "DarkRecon" */ "@/views/DarkRecon.vue");
const DarkReconConversations = () =>
  import(
    /* webpackChunkName: "DarkReconConversations" */ "@/views/DarkReconConversations.vue"
  );
const DarkReconDashboard = () =>
  import(
    /* webpackChunkName: "DarkReconDashboard" */ "@/views/DarkReconDashboard.vue"
  );
const CustomerItems = () =>
  import(/* webpackChunkName: "CustomerItems" */ "@/views/CustomerItems.vue");
const CustomerItemsChart = () =>
  import(
    /* webpackChunkName: "CustomerItems" */ "@/views/CustomerItemsChart.vue"
  );
const CustomerItemsPermalink = () =>
  import(
    /* webpackChunkName: "CustomerItemsPermalink" */ "@/views/CustomerItemsPermalink.vue"
  );
const CustomerList = () =>
  import(/* webpackChunkName: "CustomerList" */ "@/views/CustomerList.vue");
const Home = () => import(/* webpackChunkName: "Home" */ "@/views/Home.vue");
const InitLogin = () =>
  import(/* webpackChunkName: "InitLogin" */ "@/views/InitLogin.vue");
const UserProfile = () =>
  import(/* webpackChunkName: "UserProfile" */ "@/views/UserProfile.vue");
const LoggedIn = () =>
  import(/* webpackChunkName: "LoggedIn" */ "@/views/LoggedIn.vue");
const Login = () => import(/* webpackChunkName: "Login" */ "@/views/Login.vue");
const Logout = () =>
  import(/* webpackChunkName: "Logout" */ "@/views/Logout.vue");
const ThreatActors = () =>
  import(/* webpackChunkName: "ThreatActors" */ "@/views/ThreatActors.vue");
const ThreatActorProfile = () =>
  import(
    /* webpackChunkName: "ThreatActorProfile" */ "@/views/ThreatActorProfile.vue"
  );
const Reports = () =>
  import(/* webpackChunkName: "Reports" */ "@/views/Reports.vue");
const ReportDetails = () =>
  import(/* webpackChunkName: "ReportDetails" */ "@/views/ReportDetails.vue");
const ScrapingResults = () =>
  import(
    /* webpackChunkName: "ScrapingResults" */ "@/views/ScrapingResults/index.vue"
  );
const ScrapingResultDetails = () =>
  import(
    /* webpackChunkName: "ScrapingResultDetails" */ "@/views/ScrapingResultDetails/index.vue"
  );
const Style = () =>
  import(/* webpackChunkName: "Style" */ "@/views/Style/index.vue");

const Tests = () =>
  import(/* webpackChunkName: "Tests" */ "@/views/Tests/index.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/tests",
    name: "Tests",
    component: Tests,
    meta: {
      title: "Tracelight - Tests",
    },
  },
  {
    path: "/logged-in",
    name: "LoggedIn",
    component: LoggedIn,
    meta: {
      title: "Tracelight - LoggedIn",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "Tracelight - Login",
    },
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
    meta: {
      title: "Tracelight - Logout",
    },
  },
  {
    path: "/init-login",
    name: "InitLogin",
    component: InitLogin,
    meta: {
      title: "Tracelight - InitLogin",
    },
  },
  {
    path: "/",
    name: "CustomerList",
    component: CustomerList,
    meta: {
      title: "Tracelight - Customer List",
    },
  },
  {
    path: "/customer/:clientId/items",
    name: "CustomerItems",
    component: CustomerItems,
    meta: {
      title: "Tracelight - Customer Items",
    },
  },
  {
    path: "/customer/:clientId/itemschart",
    name: "CustomerItemsChart",
    component: CustomerItemsChart,
    meta: {
      title: "Tracelight - Customer Items Chart",
    },
  },
  {
    path: "/customer/:clientId/items/:itemId",
    name: "CustomerItemsPermalink",
    component: CustomerItemsPermalink,
    meta: {
      title: "Tracelight - Customer Items Permalink",
    },
  },
  {
    path: "/userprofile",
    name: "UserProfile",
    component: UserProfile,
    meta: {
      title: "Tracelight - User Profile",
    },
  },

  {
    path: "/style",
    name: "Style",
    component: Style,
    meta: {
      title: "Tracelight - Style",
    },
  },
  {
    path: "/dashboards/breach",
    name: "BreachDashboard",
    component: BreachDashboard,
    meta: {
      title: "Tracelight - Breach Dashboard",
    },
  },

  {
    path: "/darkrecon",
    name: "darkrecon",
    component: DarkRecon,
    meta: {
      title: "Tracelight - Dark Recon",
    },
  },

  {
    path: "/darkrecon/conversations/:conversationId",
    name: "conversations",
    component: DarkReconConversations,
    meta: {
      title: "Tracelight - Dark Recon Conversations",
    },
  },
  {
    path: "/darkrecon/conversations/:conversationId/messages/:messageId",
    name: "messages",
    component: DarkReconConversations,
    meta: {
      title: "Tracelight - Dark Recon Messages",
    },
  },
  {
    path: "/darkrecon/conversations/:conversationId/messages/:messageId/screenshots/",
    name: "screenshots",
    component: DarkReconScreenshots,
    meta: {
      title: "Tracelight - Dark Recon Screenshots",
    },
  },
  {
    path: "/breachmanagement",
    name: "BreachManagement",
    component: BreachManagement,
    meta: {
      title: "Tracelight - Breach Management",
    },
  },
  {
    path: "/breachmanagement/:breachId",
    name: "BreachPermalink",
    component: BreachPermalink,
    meta: {
      title: "Tracelight - Breach Permalink",
    },
  },
  {
    path: "/breachresearch",
    name: "BreachResearch",
    component: BreachResearch,
    meta: {
      title: "Tracelight - Breach Research",
    },
  },
  {
    path: "/scrapingresults",
    name: "ScrapingResults",
    component: ScrapingResults,
    meta: {
      title: "Tracelight - Scraping Results",
    },
  },

  {
    path: "/scrapingresultdetails/:resultId",
    name: "ScrapingResultDetails",
    component: ScrapingResultDetails,
    meta: {
      title: "Tracelight - Scraping Result Details",
    },
  },
  {
    path: "/dashboards/advisory",
    name: "advisory",
    component: Advisory,
    meta: {
      title: "Tracelight - Advisory",
    },
  },
  {
    path: "/threatactors",
    name: "threatactors",
    component: ThreatActors,
    meta: {
      title: "Tracelight - Threat Actors",
    },
  },
  {
    path: "/darkrecon/networks/:networkId/sources/:sourceId/authors/:authorId",
    name: "threatactorprofile",
    component: ThreatActorProfile,
    meta: {
      title: "Tracelight - Threat Actor Profile",
    },
  },
  {
    path: "/dashboards/darkrecon",
    name: "DarkReconDashboard",
    component: DarkReconDashboard,
    meta: {
      title: "Tracelight - Dark Recon Dashboard",
    },
  },
  {
    path: "/darkrecon/sitemanager",
    name: "DarkReconSiteManager",
    component: DarkReconSiteManager,
    meta: {
      title: "Tracelight - Dark Recon Site Manager",
    },
  },
  {
    path: "/darkrecon/sites/:site_id",
    name: "DarkReconSiteManagerCredentials",
    component: DarkReconSiteManagerCredentials,
    meta: {
      title: "Tracelight - Dark Recon Site Manager Credentials",
    },
  },
  {
    path: "/darkrecon/sites/:site_id/forums",
    name: "DarkReconForumManager",
    component: DarkReconForumManager,
    meta: {
      title: "Tracelight - Dark Recon Site Forum Manager",
    },
  },
  {
    path: "/customer/:clientId",
    name: "ClientIdentity",
    component: ClientIdentity,
    meta: {
      title: "Tracelight - Client Identity",
    },
  },
  {
    path: "/customer/:clientId/reports",
    name: "Reports",
    component: Reports,
    meta: {
      title: "Tracelight - Customer Reports",
    },
  },
  {
    path: "/customer/:clientId/reports/:reportId/:reportType",
    name: "ReportDetails",
    component: ReportDetails,
    meta: {
      title: "Tracelight - Customer Report Details",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const publicRoutes = ["/init-login", "/login", "/logout"];

router.beforeEach((to, from, next) => {
  const expireTime = Number(window.localStorage.getItem("expireTime"));
  const loginTime = Number(window.localStorage.getItem("loginTime"));

  if (!publicRoutes.includes(to.path)) {
    if (expireTime !== 0) {
      if (Date.now() > expireTime) {
        store.commit("replaceValue", { key: "authUser", value: false });
        window.localStorage.clear();
        window.location = "/logout";
      } else {
        next();
      }
    } else {
      next();
    }
  }

  if (window.localStorage.access_token) {
    store.commit("replaceValue", { key: "authUser", value: true });
    next();
  } else {
    if (publicRoutes.includes(to.path)) {
      next();
    } else {
      const nextUrl = window.location.pathname + window.location.search;
      window.localStorage.setItem("nextUrl", nextUrl);
      window.location = "/init-login";
    }
  }
});

export default router;
