import {
  darkReconSitesGet,
  darkReconSitesPost,
  darkReconSitesSiteIdPut,
  darkReconSitesSiteIdGet,
  darkReconSitesSiteIdCredentialsGet,
  darkReconSitesSiteIdCredentialsPut,
  darkReconSitesSiteIdCredentialsPost,
  darkReconSitesSiteIdStatusesGet,
  darkReconSitesSiteIdUrlsGet,
  darkReconSitesSiteIdUrlsDelete,
  darkReconSitesSiteIdUrlsPost,
  darkReconSitesSiteIdStatusesPost,
  setPermalinkParams,
  darkReconSiteForumsGet,
  darkReconSiteForumsPut,
  darkReconSiteCredentialSecurityQuestionsGet,
  darkReconSiteCredentialSecurityQuestionsPost,
  darkReconSiteCredentialSecurityQuestionsDelete,
} from "@/clients/tracelight";
import { formatDate } from "@/utils/common";
export default {
  namespaced: true,
  state: {
    forum_page: 1,
    forum_sort_by: "title",
    forum_sort_order: "asc",
    forum_enabled: null,
    forum_title: "",
    sites: [],
    page: 1,
    sort_by: "name",
    sort_order: "asc",
    enabled: null,
    name: "",
    has_credentials: null,
    siteName: "",
    siteNameUrl: "",
    siteNameEnabled: false,
    siteNameDescription: "",
    siteNameLanguage: "",
    siteNamePassword: null,
    siteNameUsername: null,
    selectedSite: null,
    selectedCreds: null,
    credentials: [],
    credentialSecurityQuestions: [],
    credentials_site_id: null,
    credentials_site: null,
    site_statuses: [],
    site_urls: [],
    site_forums: [],
    site_forums_total: 0,
  },
  actions: {
    addStatus(context, pay) {
      return new Promise((resolve, reject) => {
        const payload = {
          status: pay.status,
          note: pay.note,
        };
        const params = {
          site_id: context.state.credentials_site_id,
        };
        const urls_param = {
          results_per_page: 3000,
          page: 1,
          site_id: context.state.credentials_site_id,
        };
        darkReconSitesSiteIdStatusesPost(payload, params)
          .then(function (result) {
            console.log(result);
            resolve(result.data);
            darkReconSitesSiteIdStatusesGet({}, urls_param).then(function (
              res
            ) {
              context.commit("replaceValue", {
                key: "site_statuses",
                value: res.data.data,
              });
            });
          })
          .catch((error) => {
            console.warn(error.response);
            resolve(error.response.data.message);
            return;
          });
      });
    },
    addUrl(context, url) {
      return new Promise((resolve, reject) => {
        const payload = {
          url: url,
        };
        const params = {
          site_id: context.state.credentials_site_id,
        };
        const urls_param = {
          results_per_page: 3000,
          page: 1,
          site_id: context.state.credentials_site_id,
        };
        darkReconSitesSiteIdUrlsPost(payload, params)
          .then(function (result) {
            console.log(result);
            resolve(result.data.data);
            darkReconSitesSiteIdUrlsGet({}, urls_param).then(function (res) {
              context.commit("replaceValue", {
                key: "site_urls",
                value: res.data.data,
              });
            });
          })
          .catch((error) => {
            console.warn(error.response);
            resolve(error.response.data.message);
            return;
          });
      });
    },
    deleteQuestion(context, item) {
      return new Promise((resolve, reject) => {
        const payload = {};
        const params = {
          credentials_id: item.credentials_id,
          site_id: context.state.credentials_site_id,
          security_question_id: item.id,
        };
        const urls_param = {
          results_per_page: 3000,
          page: 1,
          site_id: context.state.credentials_site_id,
          credentials_id: item.credentials_id,
        };
        darkReconSiteCredentialSecurityQuestionsDelete(payload, params).then(
          function (result) {
            console.log(result);
            if (result.data.success == true) {
              resolve(result.data);
              context.dispatch("getCredentials");
            }
          }
        );
      });
    },
    deleteUrl(context, item) {
      return new Promise((resolve, reject) => {
        const payload = {};
        const params = {
          url_id: item.id,
          site_id: context.state.credentials_site_id,
        };
        const urls_param = {
          results_per_page: 3000,
          page: 1,
          site_id: context.state.credentials_site_id,
        };
        darkReconSitesSiteIdUrlsDelete(payload, params).then(function (result) {
          console.log(result);
          resolve(result.data);
          darkReconSitesSiteIdUrlsGet(payload, urls_param).then(function (res) {
            context.commit("replaceValue", {
              key: "site_urls",
              value: res.data.data,
            });
          });
        });
      });
    },
    getCredentials(context) {
      console.log(context.state.credentials_site_id);
      context.commit("replaceValue", {
        key: "credentialSecurityQuestions",
        value: [],
      });
      return new Promise((resolve, reject) => {
        const payload = {};
        const params = {
          site_id: context.state.credentials_site_id,
          results_per_page: 3000,
          page: 1,
        };
        const siteParams = { site_id: context.state.credentials_site_id };
        for (let key of Object.keys(params)) {
          if (params[key] == null || params[key] == "null") {
            delete params[key];
          }
        }

        darkReconSitesSiteIdGet(payload, siteParams).then(function (result) {
          if (result.data.data.disabled == true) {
            result.data.data.enabled = false;
          } else {
            result.data.data.enabled = true;
          }

          context.commit("replaceValue", {
            key: "credentials_site",
            value: result.data.data,
          });
        });

        darkReconSitesSiteIdStatusesGet(payload, {
          site_id: context.state.credentials_site_id,
          results_per_page: 3000,
          page: 1,
        }).then(function (result) {
          context.commit("replaceValue", {
            key: "site_statuses",
            value: result.data.data,
          });
        });

        darkReconSitesSiteIdUrlsGet(payload, {
          site_id: context.state.credentials_site_id,
          results_per_page: 3000,
          page: 1,
        }).then(function (result) {
          context.commit("replaceValue", {
            key: "site_urls",
            value: result.data.data,
          });
        });

        darkReconSitesSiteIdCredentialsGet(payload, {
          site_id: context.state.credentials_site_id,
          results_per_page: 3000,
          page: 1,
        }).then(function (result) {
          const credentials = result.data.data;
          for (const credential of credentials) {
            if (credential.disabled == true) {
              credential.enabled = false;
            } else {
              credential.enabled = true;
            }

            context.dispatch("getCredentialSecurityQuestions", credential.id);
          }

          console.log(context.state.page, credentials);
          context.commit("replaceValue", {
            key: "credentials",
            value: credentials,
          });
          resolve(credentials);
        });
      });
    },
    getCredentialSecurityQuestions(context, id) {
      return new Promise((resolve, reject) => {
        const payload = {};
        const params = {
          site_id: context.state.credentials_site_id,
          credentials_id: id,
          results_per_page: 3000,
          page: 1,
        };
        darkReconSiteCredentialSecurityQuestionsGet(payload, params).then(
          function (result) {
            console.log(result);

            const questions = result.data.data;
            for (const question of questions) {
              question.credentials_id = id;
            }
            const AllQuestions =
              context.state.credentialSecurityQuestions.concat(questions);

            context.commit("replaceValue", {
              key: "credentialSecurityQuestions",
              value: AllQuestions,
            });

            resolve(result.data.data);
          }
        );
      });
    },
    addNewSecurityQuestions(context, newCreds) {
      return new Promise((resolve, reject) => {
        console.log(newCreds);

        const payload = {
          question: newCreds.question,
          answer: newCreds.answer,
        };
        const params = {
          site_id: newCreds.site_id,
          credentials_id: newCreds.credential_id,
        };
        console.log(params);
        darkReconSiteCredentialSecurityQuestionsPost(payload, params).then(
          function (result) {
            console.log(result.data);
            resolve(result.data);
          }
        );
      });
    },
    getSites(context) {
      return new Promise((resolve, reject) => {
        const payload = {};
        const params = {
          results_per_page: 20,
          page: context.state.page,
          sort_by: context.state.sort_by,
          sort_order: context.state.sort_order,
          enabled: context.state.enabled,
          name: context.state.name,
          has_credentials: context.state.has_credentials,
        };

        setPermalinkParams(params);

        for (let key of Object.keys(params)) {
          if (params[key] == null || params[key] == "null") {
            delete params[key];
          }
        }

        darkReconSitesGet(payload, params).then(function (result) {
          const sites = result.data.data;
          for (const site of sites) {
            site.created_at = formatDate(site.created_at);
            site.last_scraped_date = formatDate(site.last_scraped_date);
            site.status_date = formatDate(site.status_created_at);
            if (site.disabled == true) {
              site.enabled = false;
            } else {
              site.enabled = true;
            }
          }

          if (context.state.page == 1) {
            context.commit("replaceValue", {
              key: "sites",
              value: [],
            });
          }

          const allSites = context.state.sites.concat(sites);

          console.log(context.state.page, allSites);
          if (sites.length > 0) {
            context.commit("replaceValue", {
              key: "sites",
              value: allSites,
            });
            resolve(sites);
          } else {
            context.commit("replaceValue", {
              key: "sites",
              value: allSites,
            });
            context.commit("replaceValue", { key: "page", value: 1 });
            resolve(sites);
          }
        });
      });
    },
    addNewSite(context) {
      return new Promise((resolve, reject) => {
        const payload = {
          name: context.state.siteName,
          url: context.state.siteNameUrl,
          enabled: context.state.siteNameEnabled,
          description: context.state.siteNameDescription,
          language: context.state.siteNameLanguage,
          password: context.state.siteNamePassword,
          username: context.state.siteNameUsername,
        };
        const params = {};

        for (let key of Object.keys(payload)) {
          if (payload[key] == null || payload[key] == "null") {
            delete payload[key];
          }
        }

        darkReconSitesPost(payload, params).then(function (result) {
          resolve(result.data);
        });
      });
    },
    updateSiteContent(context, editedSite) {
      return new Promise((resolve, reject) => {
        let disabled;
        if (editedSite.enabled == true) {
          disabled = false;
        } else {
          disabled = true;
        }
        const payload = {
          disabled: disabled,
          description: editedSite.description,
          language: editedSite.language,
        };
        const params = {
          site_id: context.state.credentials_site.id,
        };
        darkReconSitesSiteIdPut(payload, params).then(function (result) {
          resolve(result.data);
          console.log(result);
        });
      });
    },
    updateSite(context) {
      return new Promise((resolve, reject) => {
        const payload = {
          disabled: context.state.selectedSite.disabled,
        };
        const params = {
          site_id: context.state.selectedSite.id,
        };
        darkReconSitesSiteIdPut(payload, params).then(function (result) {
          resolve(result.data.data);
        });
      });
    },
    addNewCreds(context, newCreds) {
      return new Promise((resolve, reject) => {
        console.log(newCreds);
        let disabled;
        if (newCreds.enabled == true) {
          disabled = false;
        } else {
          disabled = true;
        }
        const payload = {
          username: newCreds.username,
          password: newCreds.password,
        };
        const params = {
          site_id: newCreds.site_id,
        };

        darkReconSitesSiteIdCredentialsPost(payload, params).then(function (
          result
        ) {
          if (result.data.data.created == true) {
            const par = {
              site_id: newCreds.site_id,
              credentials_id: result.data.data.id,
            };
            const pay = {
              disabled: disabled,
            };
            darkReconSitesSiteIdCredentialsPut(pay, par).then(function (res) {
              console.log(result.data.data);
              const newCred = {
                create: result.data.data,
                update: res.data.data,
              };
              resolve(newCred);
            });
          }
        });
      });
    },
    updateCreds(context) {
      console.log(context);
      return new Promise((resolve, reject) => {
        const payload = {
          disabled: context.state.selectedCreds.disabled,
        };
        const params = {
          credentials_id: context.state.selectedCreds.id,
          site_id: context.state.credentials_site.id,
        };
        darkReconSitesSiteIdCredentialsPut(payload, params).then(function (
          result
        ) {
          resolve(result.data.data);
        });
      });
    },
    updateCredsPassword(context, item) {
      console.log(context);
      return new Promise((resolve, reject) => {
        const payload = {
          password: item.password,
        };
        const params = {
          credentials_id: item.id,
          site_id: context.state.credentials_site.id,
        };
        darkReconSitesSiteIdCredentialsPut(payload, params).then(function (
          result
        ) {
          resolve(result.data.data);
        });
      });
    },
    getForums(context) {
      return new Promise((resolve, reject) => {
        const payload = {};
        const params = {
          site_id: context.state.credentials_site_id,
          results_per_page: 3000,
          page: 1,
        };
        darkReconSiteForumsGet(payload, params).then(function (result) {
          console.log(result);

          context.commit("replaceValue", {
            key: "site_forums",
            value: result.data.data,
          });
          context.commit("replaceValue", {
            key: "site_forums_total",
            value: result.data.pagination.total_results,
          });
          resolve(result.data.data);
        });
      });
    },
    updateSiteForums(context, forumData) {
      return new Promise((resolve, reject) => {
        let disabled;
        if (forumData.value == "Enable") {
          disabled = false;
        } else {
          disabled = true;
        }

        const ids = forumData.forums.map((x) => {
          return x.id;
        });
        console.log(ids);
        const payload = {
          forum_ids: ids,
          disabled: disabled,
        };
        const params = {
          site_id: context.state.credentials_site_id,
        };
        darkReconSiteForumsPut(payload, params).then(function (result) {
          console.log(result);
          resolve(result.data);
        });
      });
    },
    getAllForums(context, forumData) {
      return new Promise((resolve, reject) => {
        const payload = {};
        const params = {
          site_id: forumData.site_id,
          results_per_page: 20,
          page: context.state.forum_page,
          sort_by: context.state.forum_sort_by,
          sort_order: context.state.forum_sort_order,
          enabled: context.state.forum_enabled,
          forum_title: context.state.forum_title,
        };

        setPermalinkParams(params);

        for (let key of Object.keys(params)) {
          if (params[key] == null || params[key] == "null") {
            delete params[key];
          }
        }

        if (context.state.forum_page == 1) {
          context.commit("replaceValue", {
            key: "site_forums",
            value: [],
          });
        }

        darkReconSiteForumsGet(payload, params).then(function (result) {
          console.log(result);

          context.commit("replaceValue", {
            key: "site_forums_total",
            value: result.data.pagination.total_results,
          });

          const forums = result.data.data;

          const allForums = context.state.site_forums.concat(forums);

          console.log(context.state.page, allForums);
          if (forums.length > 0) {
            context.commit("replaceValue", {
              key: "site_forums",
              value: allForums,
            });
            resolve(forums);
          } else {
            context.commit("replaceValue", {
              key: "site_forums",
              value: allForums,
            });
            context.commit("replaceValue", { key: "forum_page", value: 1 });
            resolve(forums);
          }
        });
      });
    },
  },
  mutations: {
    clearState(state) {
      state.sites = [];
    },
    replaceValue(state, { key, value }) {
      state[key] = value;
    },
    nextPage(state) {
      state.page += 1;
    },
    forum_nextPage(state) {
      state.forum_page += 1;
    },
    nextPageCredentials(state) {
      state.credentialsPage += 1;
    },
  },
};
