<template>
  <v-app>
    <v-snackbar
      v-model="snackbar"
      color="#58abe7"
      centered
      height="106"
      :timeout="3000"
      elevation="24"
    >
      <v-icon class="float-right mr-n4 mt-n3" small @click="snackbar = false"
        >mdi-close</v-icon
      >
      <app-version></app-version>
    </v-snackbar>
    <v-app-bar app flat id="mainMenu">
      <v-toolbar class="menuBar pa-0" flat>
        <v-app-bar-nav-icon
          @click="drawer = !drawer"
          class="hidden-md-and-up ml-4 mt-1"
        ></v-app-bar-nav-icon>
        <v-toolbar-title
          style="min-width: 180px; margin-top: 0.1em"
          v-if="authUser"
          ><router-link to="/">
            <v-img
              :src="require('./assets/tl_logo.png')"
              width="146"
              height="auto" /></router-link
        ></v-toolbar-title>
        <v-toolbar-title
          style="min-width: 180px; margin-top: 0.9em"
          v-if="!authUser"
          ><router-link to="/">
            <v-img
              :src="require('./assets/tl_logo.png')"
              width="146"
              height="auto" /></router-link
        ></v-toolbar-title>
        <v-toolbar-items
          class="hidden-sm-and-down mt-4 ml-8 pa-0"
          style="width: 100%"
        >
          <v-tabs class="float-left menuTabs" v-if="authUser">
            <span v-for="item in menuItems" :key="item.title">
              <v-tab v-if="item.path !== null" :to="item.path" :ripple="false">
                {{ item.title }}
              </v-tab>
              <v-menu
                bottom
                v-else
                :nudge-bottom="42"
                :close-on-content-click="true"
                :close-on-click="true"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-tab
                    v-if="item.path == null"
                    v-bind="attrs"
                    v-on="on"
                    :ripple="false"
                  >
                    {{ item.title }}
                  </v-tab>
                </template>
                <v-list class="text-uppercase body-2">
                  <span v-if="item.title !== 'Clients'">
                    <v-list-item
                      class="menuItemColor"
                      v-for="page in item.pages"
                      :key="page.title"
                      :to="page.path"
                    >
                      <v-icon>{{ page.icon }}</v-icon
                      >&nbsp;
                      {{ page.title }}
                    </v-list-item>
                  </span>

                  <span v-if="item.title == 'Clients'">
                    <v-list-item
                      class="menuItemColor"
                      v-for="page in item.pages"
                      :key="page.title"
                      @click="clientPath(page.path)"
                    >
                      <v-icon>{{ page.icon }}</v-icon
                      >&nbsp;
                      {{ page.title }}
                    </v-list-item>
                  </span>
                </v-list>
              </v-menu>
            </span>
          </v-tabs>
          <v-spacer></v-spacer>

          <v-tooltip bottom v-if="authUser">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                plain
                :ripple="false"
                class="mt-0"
                :to="`/userprofile`"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="#476C8F"> mdi-account-circle </v-icon>
              </v-btn>
            </template>
            <span>User Profile</span>
          </v-tooltip>

          <v-tooltip bottom v-if="authUser">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                plain
                class="mt-0"
                v-bind="attrs"
                v-on="on"
                :ripple="false"
                @click="snackbar = true"
              >
                <v-icon plain color="#476C8F">mdi-information</v-icon>
              </v-btn>
            </template>
            <span>Information</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :ripple="false"
                plain
                icon
                text
                class="mr-5 mt-0"
                @click="loginOrOut"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="#476C8F" v-if="!authUser">mdi-login</v-icon>
                <v-icon color="#476C8F" v-if="authUser">mdi-logout</v-icon>
              </v-btn>
            </template>
            <span v-if="authUser">Logout</span>
            <span v-if="!authUser">Login</span>
          </v-tooltip>
        </v-toolbar-items>
      </v-toolbar>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" temporary app class="nav_drawer">
      <v-list v-if="authUser">
        <v-list-item to="/" v-if="show_customer_list == 'true'">
          <v-list-item-title class="text-uppercase">Home</v-list-item-title>
        </v-list-item>

        <v-list-group :value="false">
          <template v-slot:activator>
            <v-list-item-title class="text-uppercase">Breach</v-list-item-title>
          </template>
          <v-list-item
            to="/breachresearch"
            v-if="show_br_search_page == 'true'"
          >
            <v-list-item-icon>
              <v-icon>mdi-magnify</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Search</v-list-item-title>
          </v-list-item>

          <v-list-item
            to="/dashboards/breach"
            v-if="show_br_dashboard == 'true'"
          >
            <v-list-item-icon>
              <v-icon>mdi-view-list</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item>

          <v-list-item to="/breachmanagement">
            <v-list-item-icon>
              <v-icon>mdi-monitor-dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Management</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group :value="false">
          <template v-slot:activator>
            <v-list-item-title class="text-uppercase"
              >Dark Recon</v-list-item-title
            >
          </template>
          <v-list-item to="/darkrecon" v-if="show_darkrecon == 'true'">
            <v-list-item-icon>
              <v-icon>mdi-magnify</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Search</v-list-item-title>
          </v-list-item>

          <v-list-item
            to="/dashboards/darkrecon"
            v-if="show_darkrecon_dashboard == 'true'"
          >
            <v-list-item-icon>
              <v-icon>mdi-view-list</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item>

          <v-list-item to="/threatactors" v-if="show_threat_actors == 'true'">
            <v-list-item-icon>
              <v-icon>mdi-skull</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Threat Actors</v-list-item-title>
          </v-list-item>

          <v-list-item to="/darkrecon/sitemanager">
            <v-list-item-icon>
              <v-icon>mdi-monitor-dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Site Manager</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-item to="/scrapingresults">
          <v-list-item-title class="text-uppercase">Scraping</v-list-item-title>
        </v-list-item>

        <v-list-group :value="false">
          <template v-slot:activator>
            <v-list-item-title class="text-uppercase"
              >Clients</v-list-item-title
            >
          </template>
          <v-list-item to="/dashboards/advisory">
            <v-list-item-icon>
              <v-icon>mdi-magnify</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Advisory Dashboard</v-list-item-title>
          </v-list-item>

          <v-list-item href="https://recon.groupsense.com/a/dashboard/default">
            <v-list-item-icon>
              <v-icon>mdi-view-list</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Client Portal</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
      <v-spacer></v-spacer>

      <v-tooltip bottom v-if="authUser">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            plain
            :ripple="false"
            class="ml-5 mt-0 mr-5"
            :to="`/userprofile`"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="#476C8F"> mdi-account-circle </v-icon>
          </v-btn>
        </template>
        <span>User Profile</span>
      </v-tooltip>

      <v-tooltip bottom v-if="authUser">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            plain
            class="mt-0 mr-5 ml-5"
            v-bind="attrs"
            v-on="on"
            :ripple="false"
            @click="snackbar = true"
          >
            <v-icon plain color="#476C8F">mdi-information</v-icon>
          </v-btn>
        </template>
        <span>Information</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :ripple="false"
            plain
            icon
            text
            class="mr-0 mt-0 ml-5"
            @click="loginOrOut"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="#476C8F" v-if="!authUser">mdi-login</v-icon>
            <v-icon color="#476C8F" v-if="authUser">mdi-logout</v-icon>
          </v-btn>
        </template>
        <span v-if="authUser">Logout</span>
        <span v-if="!authUser">Login</span>
      </v-tooltip>
    </v-navigation-drawer>

    <marquee
      v-if="show_warning == 'true'"
      direction="left"
      scrollamount="20"
      behavior="scroll"
      style="
        width: 900px;
        height: 100px;
        color: #f50a0a;
        font-size: 60px;
        font-family: Comic Sans MS;
        background-color: #4fff0a;
        border-width: 10px;
        border-style: double;
        position: fixed;
        top: 0px;
        left: 400px;
        z-index: 99;
      "
      >STOP! Don't touch anything! LIVE PRODUCTION DATA - do NOT edit or delete
      anything on this site</marquee
    >

    <v-main>
      <v-container style="width: 100%; max-width: 960px; padding-top: 40px">
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import SpiderThread from "vue-material-design-icons/SpiderThread.vue";
import Squeegee from "vue-material-design-icons/Squeegee.vue";
import AppVersion from "@/components/AppVersion.vue";
import { firebaseApp } from "@/clients/firebaseConfig";
import { msToHours, unixToDate } from "@/utils/common";
import store from "@/store/index";

export default {
  name: "App",
  components: {
    SpiderThread,
    Squeegee,
    AppVersion,
  },
  data: () => ({
    drawer: false,
    snackbar: false,
    loginMessage: "Logout",
    show_br_search_page: "false",
    show_br_dashboard: "false",
    show_darkrecon_dashboard: "false",
    show_darkrecon: "false",
    show_threat_actors: "false",
    show_customer_list: "false",
    show_warning: "false",
    menuItems: [
      { title: "Home", path: "/", pages: null },
      {
        title: "Breach Research",
        path: null,
        pages: [
          {
            title: "Search",
            path: "/breachresearch",
            icon: "mdi-magnify",
          },
          {
            title: "Dashboard",
            path: "/dashboards/breach",
            icon: "mdi-view-list",
          },
          {
            title: "Management",
            path: "/breachmanagement",
            icon: "mdi-monitor-dashboard",
          },
        ],
      },
      {
        title: "Dark Recon",
        path: null,
        pages: [
          { title: "Search", path: "/darkrecon", icon: "mdi-magnify" },
          {
            title: "Dashboard",
            path: "/dashboards/darkrecon",
            icon: "mdi-view-list",
          },
          { title: "Threat Actors", path: "/threatactors", icon: "mdi-skull" },
          {
            title: "Site Manager",
            path: "/darkrecon/sitemanager",
            icon: "mdi-monitor-dashboard",
          },
        ],
      },
      { title: "Scraping", path: "/scrapingresults", pages: null },
      {
        title: "Clients",
        path: null,
        pages: [
          {
            title: "Advisory Dashboard",
            path: "/dashboards/advisory",
            icon: "mdi-chart-bar",
          },
          {
            title: "Client Portal",
            path: "https://recon.groupsense.com/a/dashboard/default",
            icon: "mdi-view-dashboard",
          },
        ],
      },
    ],
  }),
  beforeCreate: function () {
    window.timeLeft = function () {
      const expireTime = Number(window.localStorage.getItem("expireTime"));
      const loginTime = Number(window.localStorage.getItem("loginTime"));
      console.log("Login time: ", unixToDate(loginTime));
      console.log("Expires at: ", unixToDate(expireTime));
      console.log("Time now: ", unixToDate(Date.now()));
      console.log("Expired: ", Date.now() > expireTime);
      console.log("Hours left: ", msToHours(expireTime - Date.now()));
    };

    window.expireSoon = function () {
      window.localStorage.setItem("expireTime", Date.now() + 30 * 1000);
      window.timeLeft();
    };

    window.setInterval(function () {
      const publicRoutes = ["/init-login", "/login", "/logout"];
      const expireTime = Number(window.localStorage.getItem("expireTime"));
      const loginTime = Number(window.localStorage.getItem("loginTime"));
      if (!publicRoutes.includes(window.location.pathname)) {
        if (Date.now() > expireTime) {
          window.location = "/logout";
        }
      }
    }, 5000);
  },
  mounted: function () {
    const remoteConfig = firebaseApp.remoteConfig();
    remoteConfig.settings = {
      minimumFetchIntervalMillis: 36000,
      fetchTimeoutMillis: 60000,
    };

    remoteConfig
      .fetchAndActivate()
      .then((res) => {
        this.show_br_dashboard =
          remoteConfig._client.storageCache.activeConfig.show_br_dashboard;
        this.show_br_search_page =
          remoteConfig._client.storageCache.activeConfig.show_br_search_page;
        this.show_darkrecon =
          remoteConfig._client.storageCache.activeConfig.show_darkrecon;
        this.show_threat_actors =
          remoteConfig._client.storageCache.activeConfig.show_threat_actors;
        this.show_darkrecon_dashboard =
          remoteConfig._client.storageCache.activeConfig.show_darkrecon_dashboard;
        this.show_customer_list =
          remoteConfig._client.storageCache.activeConfig.show_customer_list;
        this.show_warning =
          remoteConfig._client.storageCache.activeConfig.show_warning;
      })
      .catch((err) => {
        console.error(err);
      });
  },
  computed: {
    authUser() {
      return store.state.authUser;
    },
    loggedIn: () => {
      if (window.localStorage.access_token) {
        store.commit("replaceValue", { key: "authUser", value: true });
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    clientPath(path) {
      if (path == "/dashboards/advisory") {
        this.$router.push("/dashboards/advisory");
      } else {
        window.location = "https://recon.groupsense.com/support/login";
      }
    },
    loginOrOut: function () {
      if (this.loggedIn) {
        window.localStorage.clear();
        store.commit("replaceValue", { key: "authUser", value: false });
        window.location = "/logout";
      } else {
        window.location = "/init-login";
      }
    },
  },
  watch: {
    $route(to, from) {
      document.title = to.meta.title || "Tracelight";
      this.$mixpanel.track_pageview();
    },
  },
};
</script>

<style>
@import url("https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css");

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

html,
body {
  background: #153059 !important;
}
.nav_drawer .v-list-item__title {
  font-size: 0.9em;
  color: #fff;
}

.nav_drawer .v-list-item:hover {
  background: #58abe7;
}

.nav_drawer .v-list-item__title:hover {
  font-size: 0.9em;
  color: #fff !important;
}
.theme--dark.v-navigation-drawer {
  background-color: #112748 !important;
}
.v-application {
  font-weight: 300;
}

.theme--dark.v-timeline .v-timeline-item__dot {
  background: #58abe7 !important;
}

.v-input--selection-controls__input .v-icon {
  color: #58abe7 !important;
}

.theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-weight: 500px;
  text-transform: uppercase;
  color: #41669e !important;
}

.glow-accent:hover {
  box-shadow: 0px 0px 10px 2px #f44336;
}

.glow-accent-circle:hover {
  box-shadow: 0px 0px 10px 2px #f44336;
  border-radius: 100%;
}

.theme--dark.v-data-table {
  background-color: #153059 !important;
}

.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: thin solid rgba(255, 255, 255, 0) !important;
}
.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: thin solid rgba(255, 255, 255, 0) !important;
}
.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: thin solid rgba(255, 255, 255, 0) !important;
}

.spider-thread-icon {
  font-size: 1.8em !important;
  margin-left: -0.1em;
}

.squeegee-icon {
  font-size: 1.8em !important;
  margin-left: -0.1em;
}

#mainMenu .v-toolbar__content {
  padding: 0px !important;
  height: unset !important;
}
.menuTabs .v-tab {
  letter-spacing: unset !important;
}

.menuTabs .v-list-item {
  color: #476c8f !important;
}

#mainMenu .v-slide-group__content {
  margin-top: 0.5em;
  min-height: 32px;
}

#mainMenu .v-tab:before {
  display: none;
}

.menuItemColor .v-icon {
  color: #476c8f !important;
}

.theme--dark.menuItemColor.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):hover {
  color: #fff !important;
}

.theme--dark.menuItemColor.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #476c8f !important;
}
.theme--dark.menuBar.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: #476c8f !important;
}
.theme--dark.v-list-item:hover::before {
  opacity: 0;
}

.theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: #476c8f !important;
}

.theme--dark.menuItemColor.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):hover {
  background: #58abe7;
  color: #fff !important;
}

.theme--dark.menuItemColor.v-list-item--active::before {
  background: #58abe7 !important;
}

.v-list .v-list-item--active .v-icon,
.v-list .v-list-item:hover .v-icon {
  color: #fff !important;
}

.menuBar .v-btn--icon.v-size--default .v-icon:hover,
.menuBar .v-btn--fab.v-size--default .v-icon:hover {
  color: #fff !important;
}

.nav_drawer .v-btn--icon.v-size--default .v-icon,
.nav_drawer .v-btn--fab.v-size--default .v-icon {
  color: #fff !important;
}
</style>
