import {
  breachesGet,
  breachesSourceGet,
  getBreach,
  breachesBreachIdRawfileGet,
  setPermalinkParams,
} from "../../clients/tracelight";
import { formatDate } from "@/utils/common";

const breachManagement = {
  namespaced: true,
  state: {
    filenames: [],
    breach: {},
    breachId: null,
    breaches: [],
    breachSources: [],
    source: null,
    acquireDateStart: null,
    acquireDateEnd: null,
    releaseDateStart: null,
    releaseDateEnd: null,
    status: [],
    not_status: null,
    searchTerm: "",
    page: 1,
    sortBy: "status",
    sortOrder: "asc",
  },
  mutations: {
    addMoreBreaches(state, payload) {
      state.breaches = state.breaches.concat(payload);
    },
    addMoreBreachSources(state, payload) {
      state.breachSources = state.breachSources.concat(payload);
      state.breachSources.sort();
    },
    replaceValue(state, { key, value }) {
      state[key] = value;
    },
    nextPage(state) {
      state.page++;
    },
  },
  actions: {
    listFilenames(context) {
      breachesBreachIdRawfileGet({}, { breach_id: context.state.breachId })
        .then(function (result) {
          console.log(result);
          context.commit("replaceValue", {
            key: "filenames",
            value: result.data.data.files,
          });
        })
        .catch(function (error) {
          console.debug(error);
        });
    },
    addBreachSources(context, query) {
      const payload = {};
      const params = {
        source: query,
      };
      breachesSourceGet(payload, params)
        .then(function (result) {
          context.commit("addMoreBreachSources", result.data.data);
        })
        .catch(function (error) {
          console.debug(error);
        });
    },
    updatePage(context) {
      context.commit("nextPage");
      //context.dispatch("getBreaches", true);
    },
    getSingleBreach(context) {
      const params = { breach_id: context.state.breachId };
      getBreach({}, params, {}).then(function (result) {
        const formattedStatus = result.data.data.status.sort(function (a, b) {
          return new Date(a.created_at) - new Date(b.created_at);
        });
        result.data.data.status = formattedStatus;
        context.commit("replaceValue", {
          key: "breach",
          value: result.data.data,
        });
      });
    },

    getBreaches(context, nextPage = false) {
      if (!nextPage) {
        context.commit("replaceValue", {
          key: "page",
          value: 1,
        });
      }

      let status = context.state.status.join(",");

      const payload = {};
      const params = {
        page: context.state.page,
        results_per_page: 50,

        source: context.state.source,
        status: status,
        sort_by: context.state.sortBy,
        sort_order: context.state.sortOrder,
        not_status: context.state.not_status,
        acquired_before: context.state.acquireDateEnd,
        acquired_after: context.state.acquireDateStart,
        released_before: context.state.releaseDateEnd,
        released_after: context.state.releaseDateStart,
      };

      if (context.state.searchTerm.length > 1) {
        params.q = context.state.searchTerm;
      } else {
        params.q = null;
      }

      setPermalinkParams(params);

      return new Promise((resolve, reject) => {
        breachesGet(payload, params)
          .then(function (result) {
            if (result.data.data.length > 0) {
              resolve(result.data.data);
              if (nextPage) {
                context.commit("addMoreBreaches", result.data.data);
              } else {
                context.commit("replaceValue", {
                  key: "breaches",
                  value: result.data.data,
                });
              }
            } else {
              resolve(result.data.data);
              if (nextPage) {
                return;
              } else {
                context.commit("replaceValue", {
                  key: "breaches",
                  value: [],
                });
              }
            }
          })
          .catch(function (error) {
            console.debug(error);
          });
      });
    },
    updateBreaches(context, { key, value }) {
      function checkDates(dateKeyInfo) {
        if (value != null) {
          value = value.format("YYYY-MM-DD");
        }
        let dateKeyStart = null;
        let dateKeyEnd = null;
        if (dateKeyInfo.position == "start") {
          dateKeyStart = value;
          dateKeyEnd = context.state[dateKeyInfo.pair];
        } else {
          dateKeyStart = context.state[dateKeyInfo.pair];
          dateKeyEnd = value;
        }
        if (!(dateKeyStart && dateKeyEnd)) {
          getNewBreaches = false;
        }
        return [value, getNewBreaches];
      }

      let getNewBreaches = true;

      const dateKeys = {
        acquireDateStart: { position: "start", pair: "acquireDateEnd" },
        acquireDateEnd: { position: "end", pair: "acquireDateStart" },
        releaseDateStart: { position: "start", pair: "releaseDateEnd" },
        releaseDateEnd: { position: "end", pair: "releaseDateStart" },
      };

      if (Object.keys(dateKeys).includes(key)) {
        [value, getNewBreaches] = checkDates(dateKeys[key]);
      }

      context.commit("replaceValue", {
        key,
        value,
      });

      if (key == "searchTerm") {
        if ([1].includes(context.state.searchTerm.length)) {
          getNewBreaches = false;
        }
      }

      if (getNewBreaches) {
        context.dispatch("getBreaches");
      }
    },
  },
};

export default breachManagement;
