<template>
  <div>
    <span
      class="font-weight-light"
      style="
        font-size: 0.8125rem;
        color: #fff;
        color: #fff;
        left: 5%;
        position: absolute;
        bottom: 80px;
      "
      >Version: ##-version-##</span
    >
    <br />
    <span
      class="font-weight-light"
      style="
        font-size: 0.8125rem;
        color: #fff;
        color: #fff;
        left: 5%;
        position: absolute;
        bottom: 55px;
      "
      >Build: ##-build-##</span
    >
    <br />
    <span
      class="font-weight-light"
      style="
        font-size: 0.8125rem;
        color: #fff;
        color: #fff;
        left: 5%;
        position: absolute;
        bottom: 30px;
      "
      >Release Date: ##-date-##</span
    >
    <br />
    <span
      class="font-weight-light"
      style="
        font-size: 0.8125rem;
        color: #fff;
        left: 5%;
        position: absolute;
        bottom: 5px;
      "
      >&copy; tracelight.io - Groupsense {{ new Date().getFullYear() }}</span
    >
  </div>
</template>

<script>
export default {
  name: "app-version",
};
</script>
