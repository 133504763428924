import {
  statisticsBreachesGet,
  statisticsBreachesTotalGet,
  statisticsBreachesStatsIdGet,
} from "../../clients/tracelight";

const breachDashboard = {
  namespaced: true,
  state: {
    breachStats: [],
    breachStatsTotal: [],
  },
  actions: {
    updateBreachStats(context, { page, latest, currentSortValue }) {
      let payload;
      const params = {};
      if (latest) {
        payload = {
          results_per_page: 21,
          page: page,
          latest: latest,
          sort_by: currentSortValue,
          sort_order: "desc",
        };
      } else {
        payload = {
          results_per_page: 21,
          page: page,
          sort_by: currentSortValue,
          sort_order: "desc",
        };
      }
      statisticsBreachesGet(params, payload)
        .then(function (result) {
          console.warn(result);
          context.commit("fillBreachStats", result.data.data);
          for (let r = 0; r < result.data.data.length; r++) {
            let eachIt = result.data.data[r];
            if (eachIt.breach.name) {
              eachIt.name = eachIt.breach.name;
            }
          }
        })
        .catch(function (error) {
          console.debug(error);
          return;
        });
    },
  },
  mutations: {
    fillBreachStats(state, payload) {
      state.breachStats = payload;
    },
    addMoreBreachStats(state, payload) {
      state.breachStats = state.breachStats.concat(payload);
    },
    fillBreachStatsTotal(state, payload) {
      state.breachStatsTotal = payload;
    },
  },
};

export default breachDashboard;
