import axios from "axios";

/* Detecting if logged in or not...

   1. Catch errors here in the client and direct to login if there is
   a 403 error

   2. Check if window.localStorage contains an access_key value. If
   the user manually logged out, then there will be no value.

   ...for now we are redirecting to /init-login because Firefox fucks
   us, but it might be better if we can include all of the code right
   here and avoid the extra page load.
   
 */

let api = "https://internal-api.tracelight.io";

const devHosts = [
  "localhost:8080",
  "pilot.tracelight.io",
  "pilot-stable.tracelight.io",
];

if (devHosts.includes(window.location.host)) {
  api = "https://internal-api-pilot.tracelight.io";
}

/* Auth Specific */
export function tlMe() {
  const path = "/auth/me";
  const url = `${api}${path}`;
  const token = window.localStorage.getItem("access_token");
  return new Promise((resolve, reject) => {
    axios({
      method: "get",
      url: url,
      headers: { Authorization: `Bearer ${token}`, "x-api-key": "112358" },
    })
      .then((r) => {
        const apiKey = r.data.api_keys[0].value;
        const userId = r.data.external_id;
        window.localStorage.setItem("apiKey", apiKey);
        window.localStorage.setItem("userId", userId);
        resolve(r);
      })
      .catch((e) => resolve(e));
  });
}

/* TL Main API Function */
function tlAxios(httpMethod) {
  /* const apiKey = window.localStorage.getItem("apiKey");
   * const token = window.localStorage.getItem("access_token");
   * const headers = { "x-api-key": apiKey, Authorization: `Bearer ${token}` }; */

  return function (path) {
    return function (payload, params) {
      // Instead of getting the keys from local storage when the functions are
      // created, we should get them each time the function is called, this way
      // we can update the keys without reloading the app (i.e. when logging in)

      const token = window.localStorage.getItem("access_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      /* ### Let's take a closer look at this. */
      /* 1. Could we do the path.replace inside of the Object.entries
         loop, using the key as part of the regular expression, and
         then avoid creating the extra array?

         2. Can path.includes have a false positive match?
         e.g. `/customer/` and `{customer}` both match the key
         `customer`. At the moment, we don't have conflicting keys
         (because we have params like `customer_id`), but it could
         happen if the params were named differently. So instead we
         could just use an exact match regular expression and avoid
         the problem (`{key}`)... it would actually be the same regex
         as the replace regex.

         let path
         for key, value in object.entries, path = path.replace({key}) with `value`
         if the key isn't in the path, then the regex just won't match and won't do anything.

         actually, we don't even need the regex... just replace the string with the actual value.
         
       */

      let pathWithParams = path;
      const queryParams = {};
      for (const [key, value] of Object.entries(params)) {
        if (pathWithParams.includes(key)) {
          pathWithParams = pathWithParams.replace("{" + key + "}", value);
          delete params[key];
        } else {
          queryParams[key] = params[key];
        }
      }
      const url = `${api}${pathWithParams}`;

      let options = {
        method: httpMethod,
        url: url,
        headers: headers,
        params: params,
        data: payload,
      };
      if (httpMethod == "get" || httpMethod == "delete") {
        delete options.data;
      }

      return new Promise((resolve, reject) => {
        axios(options)
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            if (e.response) {
              if (e.response.status == 401) {
                const nextUrl =
                  window.location.pathname + window.location.search;
                window.localStorage.setItem("nextUrl", nextUrl);
                if (!window.location.host.includes("localhost12345")) {
                  console.log("Redirecting to logout...");
                  window.location = "/logout";
                }
              }
              console.log("???", e.response.status);
              console.error("", e.message);
              console.error("++++", e.request);
              console.error(e.response);
              console.error(e.config);
              reject(e);
            } else {
              // Ideally, we would get a 403 Not Authorized error from
              // the API, but instead, incorrect auth leads to a CORS error
              // and no response... so we are checking for no response and
              // then sending the user to login. The problem with this is
              // that the error may not actually be caused by auth (for
              // example, trying to access a non-existent API resource
              // would show the same behavior);
              return;
              console.log("No response");
              console.error(e.message);
              console.error(e.request);
              console.error(e.config);
              const nextUrl = window.location.pathname + window.location.search;
              window.localStorage.setItem("nextUrl", nextUrl);

              if (!window.location.host.includes("localhost")) {
                window.location = "/init-login";
              }
            }
          });
      });
    };
  };
}

export const setPermalinkParams = (params) => {
  let url = new URL(window.location);
  for (const [key, value] of Object.entries(params)) {
    url.searchParams.set(key, value);
  }
  window.history.replaceState({}, "", url.toString());
};

/* TL Methods */
const tlGet = tlAxios("get");
const tlPost = tlAxios("post");
const tlPut = tlAxios("put");
const tlDelete = tlAxios("delete");

/* Auth */
export const logout = tlGet("/auth/logout");

/* Users */
export const recordLogin = tlPost("/users/login");
export const getUser = tlGet("/v1/users/me");
export const getUserKeys = tlGet("/v1/users/me/apikeys");
export const listUsers = tlGet("/v1/users");
export const createUser = tlPost("/v1/users");
export const createUserKey = tlPost("/v1/users/key");
export const usersUserIdKeyGet = tlGet("/v1/users/{user_id}/key");
export const usersGet = tlGet("/v1/users");
export const userIdGet = tlGet("/v1/users/{user_id}");
export const usersKeyGet = tlGet("/v1/users/{user_id}/apikeys");
export const usersKeyPost = tlPost("/v1/users/{user_id}/apikeys");
export const userApikeyPost = tlPost("/v1/users/me/apikeys");

/* Types */
export const listItemTypes = tlGet("/v1/types/items");
export const listResultTypes = tlGet("/v1/types/results");
export const apiKeyTypes = tlGet("/users/api_key_types");
export const reportTypesGet = tlGet("/v1/types/reports");
export const userApikeyTypes = tlGet("/v1/types/apikeys/user");

/* Customers */
export const listCustomers = tlGet("/v1/clients");
export const createCustomer = tlPost("/beta/clients");
export const updateCustomer = tlPut("/beta/clients/{client_id}");
export const listReports = tlGet("/v1/clients/{client_id}/reports");
export const customersStatsGet = tlGet("/beta/clients/stats");
export const customerSummary = tlGet("/beta/clients/{client_id}/summary");
export const customerProgress = tlGet("/beta/clients/{client_id}/progress");
export const customerProgressItems = tlGet(
  "/beta/clients/{client_id}/progress/items"
);
export const customerProgressResults = tlGet(
  "/beta/clients/{client_id}/progress/items/results"
);

export const getCustomer = tlGet("/v1/clients/{client_id}");
export const deleteCustomer = tlDelete("/v1/clients/{client_id}");
export const productsGet = tlGet("/beta/products");
export const partnersGet = tlGet("/beta/partners");
export const getProductTerms = tlGet("/beta/products/{product_id}/terms");
export const getProductByPartner = tlGet(
  "/beta/products?partner_id={partner_id}&results_per_page=100"
);

export const createMonitoringItem = tlPost("/v1/clients/{client_id}/items");
export const updateMonitoringItem = tlPut(
  "/beta/clients/{client_id}/items/{item_id}"
);
export const getMonitoringItem = tlGet(
  "/beta/clients/{client_id}/items/{item_id}"
);
export const updateMonitoringItemResult = tlPut(
  "/v1/clients/{client_id}/results/{result_id}"
);
export const getResultItem = tlGet(
  "/v1/clients/{client_id}/results/{result_id}"
);
export const customersCustomerIdUsersGet = tlGet(
  "/beta/clients/{client_id}/users"
);
export const customersCustomerIdUsersPost = tlPost(
  "/beta/clients/{client_id}/users"
);
export const customersCustomerIdPurchasesPost = tlPost(
  "/beta/clients/{client_id}/purchases"
);
export const customersCustomerIdEmailDomainsGet = tlGet(
  "/beta/clients/{client_id}/email_domains"
);
export const customersCustomerIdEmailDomainsPost = tlPost(
  "/beta/clients/{client_id}/email_domains"
);
export const customersCustomerIdEmailDomainsDomainIdDelete = tlDelete(
  "/beta/clients/{client_id}/email_domains/{domain_id}"
);
export const listMonitoringItems = tlGet("/v1/clients/{client_id}/items");
export const listMonitoringResults = tlGet("/v1/clients/{client_id}/results");

export const customersCustomerIdPurchasesGet = tlGet(
  "/beta/clients/{client_id}/purchases"
);
export const deleteResult = tlDelete(
  "/v1/clients/{client_id}/results/{result_id}"
);
export const deleteItem = tlDelete("/v1/clients/{client_id}/items/{item_id}");
export const putMonitoringItemsEnabled = tlPut("/v1/clients/{client_id}/items");
export const customersCustomerIdViewsGet = tlGet(
  "/v1/clients/{client_id}/views"
);
export const putMonitoringResultsEnabled = tlPut(
  "/v1/clients/{client_id}/results"
);
export const getReport = tlGet(
  "/v1/clients/{client_id}/reports/{report_type}/{report_id}"
);

export const getReportComponentJson = tlGet(
  "/v1/clients/{client_id}/reports/{report_type}/{report_id}/{component}/{csvtype}/json"
);

export const getReportComponentCsv = tlGet(
  "/v1/clients/{client_id}/reports/{report_type}/{report_id}/{component}/{csvtype}/csv"
);

export const getReportArchive = tlGet(
  "/beta/clients/{client_id}/reports/{report_type}/{report_id}/archive"
);

export const createReport = tlPost(
  "/v1/clients/{client_id}/reports/{report_type}"
);
export const deleteCustomerUser = tlDelete(
  "/beta/clients/{client_id}/users/{user_id}"
);

export const deleteCustomerPurchase = tlDelete(
  "/beta/clients/{client_id}/purchases/{purchase_id}"
);

/* Plugin */
export const listScrapingResults = tlGet("/v1/users/scrapes");
export const pluginDataIdDownloadGet = tlGet(
  "/v1/users/scrapes/{scrape_id}/download"
);
export const getScrapingResults = tlGet("/v1/users/scrapes/{scrape_id}");
export const editPluginDataPut = tlPut("/v1/users/scrapes/{scrape_id}");

/* Breaches */
export const breachesGet = tlGet("/beta/breaches");
export const getBreach = tlGet("/beta/breaches/{breach_id}");
export const statisticsBreachesGet = tlGet("/beta/breaches/statistics");
export const statisticsBreachesTotalGet = tlGet(
  "/beta/breaches/statistics/total"
);
export const breachesSearchTermsPost = tlPost("/beta/breaches/search/terms");
export const breachesSearchPost = tlPost("/beta/breaches/search");
export const breachesSearchCountPost = tlPost("/beta/breaches/search/count");
export const breachesSearchDownloadPost = tlPost(
  "/beta/breaches/search/download"
);
export const breachesBreachIdPut = tlPut("/beta/breaches/{breach_id}");
export const breachesPost = tlPost("/beta/breaches");
export const breachesSlugPost = tlPost("/beta/breaches/slug");
export const breachesSourceGet = tlGet("/beta/breaches/source");
export const breachDelete = tlDelete("/beta/breaches/{breach_id}");
export const statisticsBreachesStatsIdGet = tlGet(
  "/beta/statistics/breaches/{stats_id}"
);
export const breachesBreachIdStatusPost = tlPost(
  "/beta/breaches/{breach_id}/status"
);
export const breachesBreachIdRawfileGet = tlGet(
  "/beta/breaches/{breach_id}/rawfiles"
);
export const breachesBreachIdRawfileDelete = tlDelete(
  "/beta/breaches/{breach_id}/rawfiles"
);
export const breachesBreachIdRawfilePost = tlPost(
  "/beta/breaches/{breach_id}/rawfiles"
);

/* Dark Recon */
export const darkreconSearchGet = tlGet("/beta/darkrecon/search");
export const darkreconStatsGet = tlGet("/beta/darkrecon/stats");
export const darkreconSearchStatsGet = tlGet("/beta/darkrecon/search/stats");
export const darkreconStatsSourcesGet = tlGet("/beta/darkrecon/stats/sources");
export const darkreconSearchExpandedGet = tlGet(
  "/beta/darkrecon/conversations/{conversation_id}/messages/slice"
);
export const threatActorSearch = tlGet("/beta/darkrecon/search/authors");
export const darkreconTrends = tlGet("/beta/darkrecon/search/trends");
export const darkreconMessagesMessageidGet = tlGet(
  "/beta/darkrecon/messages/{message_id}"
);
export const darkreconConversationsConversationidGet = tlGet(
  "/beta/darkrecon/conversations/{conversation_id}"
);
export const darkreconConversationsConversationidMessagesGet = tlGet(
  "/beta/darkrecon/conversations/{conversation_id}/messages"
);
export const darkreconMessagesMessageidScreenshotsObfGet = tlGet(
  "/beta/darkrecon/messages/{message_id}/screenshots/obf"
);

export const darkreconAuthorsAuthoridConversationsGet = tlGet(
  "/beta/darkrecon/authors/{author_id}/conversations"
);

export const darkreconAuthorsAuthoridGet = tlGet(
  "/beta/darkrecon/authors/{author_id}"
);
export const darkReconSitesGet = tlGet("/beta/darkrecon/sites");

export const darkReconSitesPost = tlPost("/beta/darkrecon/sites");

export const darkReconSitesSiteIdPut = tlPut("/beta/darkrecon/sites/{site_id}");

export const darkReconSitesSiteIdGet = tlGet("/beta/darkrecon/sites/{site_id}");

export const darkReconSitesSiteIdCredentialsGet = tlGet(
  "/beta/darkrecon/sites/{site_id}/credentials"
);

export const darkReconSitesSiteIdCredentialsPut = tlPut(
  "/beta/darkrecon/sites/{site_id}/credentials/{credentials_id}"
);

export const darkReconSitesSiteIdCredentialsPost = tlPost(
  "/beta/darkrecon/sites/{site_id}/credentials"
);

export const darkReconSitesSiteIdStatusesGet = tlGet(
  "/beta/darkrecon/sites/{site_id}/statuses"
);

export const darkReconSitesSiteIdUrlsGet = tlGet(
  "/beta/darkrecon/sites/{site_id}/urls"
);

export const darkReconSitesSiteIdUrlsPost = tlPost(
  "/beta/darkrecon/sites/{site_id}/urls"
);

export const darkReconSitesSiteIdUrlsDelete = tlDelete(
  "/beta/darkrecon/sites/{site_id}/urls/{url_id}"
);

export const darkReconSitesSiteIdStatusesPost = tlPost(
  "/beta/darkrecon/sites/{site_id}/statuses"
);

export const darkReconSiteForumsGet = tlGet(
  "/beta/darkrecon/sites/{site_id}/forums"
);

export const darkReconSiteForumsPut = tlPut(
  "/beta/darkrecon/sites/{site_id}/forums"
);

export const darkReconSiteCredentialSecurityQuestionsGet = tlGet(
  "/beta/darkrecon/sites/{site_id}/credentials/{credentials_id}/security_questions"
);

export const darkReconSiteCredentialSecurityQuestionsPost = tlPost(
  "/beta/darkrecon/sites/{site_id}/credentials/{credentials_id}/security_questions"
);

export const darkReconSiteCredentialSecurityQuestionsDelete = tlDelete(
  "/beta/darkrecon/sites/{site_id}/credentials/{credentials_id}/security_questions/{security_question_id}"
);

/* Misc */
export const osintSourcesPastebinDownloadGet = tlGet(
  "/osint/sources/pastebin/download"
);
export const recentResultsGet = tlGet("/recent/results");
