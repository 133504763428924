import { threatActorSearch } from "../../clients/tracelight.js";

const threatActors = {
  namespaced: true,
  state: {
    authors: [],
    queryString: "",
    currentPage: [],
    page: 1,
    notFound: false,
    loading: false,
  },
  mutations: {
    clearState(state) {
      state.authors = [];
      state.queryString = "";
      state.currentPage = [];
      state.page = 1;
      state.notFound = false;
      state.loading = false;
    },
    replaceValue(state, { key, value }) {
      state[key] = value;
    },
    nextPage(state) {
      state.page += 1;
    },
  },
  actions: {
    getAuthors(context, { key, value }) {
      return new Promise((resolve, reject) => {
        context.commit("replaceValue", { key: "loading", value: true });
        if (value == undefined || value.length == 0) {
          return;
        }

        context.commit("replaceValue", {
          key,
          value,
        });
        context.state.authors = [];
        context.state.page = 1;

        const payload = {};
        const params = {
          q: context.state.queryString,
        };
        threatActorSearch(payload, params).then(function (result) {
          if (result.data.data.authors.length == 0) {
            context.state.notFound = true;
          } else {
            context.state.notFound = false;
          }
          context.state.authors = result.data.data.authors;
          context.commit("replaceValue", { key: "loading", value: false });
          resolve(result.data.data.authors);
        });
      });
    },
    loadMore(context) {
      context.commit("replaceValue", { key: "loading", value: true });
      context.commit("nextPage");
      if (
        context.state.queryString == undefined ||
        context.state.queryString.length == 0
      ) {
        return null;
      }

      return new Promise((resolve, reject) => {
        const payload = {};
        const params = {
          q: context.state.queryString,
          page: context.state.page,
        };
        threatActorSearch(payload, params).then(function (result) {
          const allAuthors = context.state.authors.concat(result.data.data.authors);
          context.commit("replaceValue", { key: "authors", value: allAuthors });
          context.commit("replaceValue", {
            key: "currentPage",
            value: result.data.data.authors,
          });
          context.commit("replaceValue", { key: "loading", value: false });
          resolve(result.data.data.authors);
        });
      });
    },
  },
};

export default threatActors;
