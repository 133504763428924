import {
  breachesSearchTermsPost,
  breachesSearchPost,
  breachesSearchCountPost,
  breachesSearchDownloadPost,
} from "../../clients/tracelight";

const breachResearch = {
  namespaced: true,
  state: {
    processedBreachSearchTerms: [],
    breachSearchPostResults: [],
  },
  mutations: {
    fillProcessedBreachSearchTerms(state, payload) {
      state.processedBreachSearchTerms = payload;
    },
    clearBreachResearch(state, payload) {
      state.processedBreachSearchTerms = [];
      state.breachSearchPostResults = [];
    },
    fillBreachSearchPostResults(state, payload) {
      state.breachSearchPostResults = payload;
    },
    addMoreBreachSearchPostResults(state, payload) {
      state.breachSearchPostResults.push(payload);
    },
  },
};

export default breachResearch;
