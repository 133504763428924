import Vue from "vue";
import Vuex from "vuex";
import breachDashboard from "./breachDashboard";
import breachManagement from "./breachManagement";
import breachResearch from "./breachResearch";
import darkRecon from "./darkRecon";
import clientIdentity from "./clientIdentity";
import customerList from "./customerList";
import threatActors from "./threatActors";
import scrapingResult from "./scrapingResult";
import darkReconSiteManager from "./darkReconSiteManager";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    authUser: false,
  },
  mutations: {
    replaceValue(state, { key, value }) {
      state[key] = value;
    },
  },
  actions: {},
  modules: {
    breachDashboard,
    breachManagement,
    breachResearch,
    customerList,
    darkRecon,
    threatActors,
    scrapingResult,
    clientIdentity,
    darkReconSiteManager,
  },
});
