import {
  getCustomer,
  getUser,
  listCustomers,
  listUsers,
  listItemTypes,
  listResultTypes,
  customerProgress,
  customerProgressItems,
  customerProgressResults,
  customerSummary,
  updateCustomer,
  deleteCustomer,
  createCustomer,
  listMonitoringItems,
  customersStatsGet,
  setPermalinkParams,
  getMonitoringItem,
} from "../../clients/tracelight";
import { formatDate } from "@/utils/common";
import moment from "moment";
import { Store } from "vuex";

const customerList = {
  namespaced: true,
  state: {
    user_id: null,
    sortBy: "value",
    sortOrder: "asc",
    noItems: false,
    all: null,
    isAdd: true,
    isOpen: false,
    is_trial: null,
    customer_enabled: true,
    customers: [],
    customerName: "",
    summary: [],
    progress: [],
    readiness: [],
    users: [],
    itemTypes: [],
    summaryPanels: [],
    resultTypes: [],
    clipboardContent: null,
    items: [],
    itemsPage: 1,
    currentItemType: "organization_name",
    currentItemId: null,
    q: "",
    enabled: true,
    clientId: null,
    customerStatsActive: 0,
    customerStatsTrial: 0,
    customerStatsDisabled: 0,
    reports: [],
    reportTypes: [],
    moment: moment,
  },
  actions: {
    loadMoreItems(context) {
      context.commit("nextPage");

      return new Promise((resolve) => {
        const params = {
          client_id: context.state.clientId,
          results_per_page: 20,
          page: context.state.itemsPage,
          item_type: context.state.currentItemType,
          enabled: context.state.enabled,
          q: context.state.q,

          sort_by: context.state.sortBy,
          sort_order: context.state.sortOrder,
        };

        for (let key of Object.keys(params)) {
          if (params[key] == null || params[key] == "null") {
            delete params[key];
          }
        }

        const payload = {};
        listMonitoringItems(payload, params).then(function (result) {
          const itemIcons = {
            5: "mdi-domain",
            2: "mdi-web",
          };

          for (let c = 0; c < result.data.data.length; c++) {
            let item = result.data.data[c];
            item.parent = "";
            item.main_value = item.value.value;
            item.modified = formatDate(item.modified_at);

            if (item.created_from) {
              item.parent = item.created_from.value.value;
            }

            if (item.type.id == "UVlslgGDT0WnmWr2R0hltA") {
              item.icon = itemIcons[5];
            }
          }

          const allItems = context.state.items.concat(result.data.data);
          context.commit("fillCustomerItems", allItems);
          resolve(result.data);
        });
      });
    },

    updateCustomerItems(context) {
      let params = {
        client_id: context.state.clientId,
        results_per_page: 20,
        page: 1,
        item_type: context.state.currentItemType,

        q: context.state.q,
        enabled: context.state.enabled,
        sort_by: context.state.sortBy,
        sort_order: context.state.sortOrder,
      };

      for (let key of Object.keys(params)) {
        if (params[key] == null || params[key] == "null") {
          delete params[key];
        }
      }

      const permalinkParams = { ...params };
      delete permalinkParams.client_id;
      setPermalinkParams(permalinkParams);
      console.log(context.state.clientId);
      const payload = {};
      let pan = [];
      customerSummary(payload, {
        client_id: context.state.clientId,
        enabled: true,
      })
        .then(function (result) {
          result.data.data.client_id = context.state.clientId;

          function processCustomerItem(
            array,
            data,
            typeName,
            icon,
            displayName
          ) {
            let item = data.data.items.filter(function (item) {
              return item.item_type_name == typeName;
            });

            if (icon == "emaildomain" || icon == "emailhost") {
              if (item.length == 1) {
                array.push({
                  customIcon: icon,
                  value: item[0].count,
                  type: displayName,
                });
              } else {
                array.push({
                  customIcon: icon,
                  value: 0,
                  type: displayName,
                });
              }
            } else {
              if (item.length == 1) {
                array.push({
                  icon: icon,
                  value: item[0].count,
                  type: displayName,
                });
              } else {
                array.push({
                  icon: icon,
                  value: 0,
                  type: displayName,
                });
              }
            }
          }

          const allItems = [
            {
              typeName: "organization_name",
              icon: "mdi-domain",
              displayName: "Organization names",
            },
            {
              typeName: "domain",
              icon: "mdi-web",
              displayName: "Domains",
            },
            {
              typeName: "email_domain",
              icon: "emaildomain",
              displayName: "Email Domains",
            },
            {
              typeName: "host",
              icon: "mdi-server-network",
              displayName: "Host names",
            },
            {
              typeName: "ip_address",
              icon: "mdi-ip-network",
              displayName: "IP Addresses",
            },
            {
              typeName: "cidr",
              icon: "mdi-wan",
              displayName: "CIDR Blocks",
            },
            {
              typeName: "whitelisted_apk_hash",
              icon: "mdi-cellphone-text",
              displayName: "Whitelisted APK Hashes",
            },
            {
              typeName: "email_address",
              icon: "mdi-email",
              displayName: "Email Addresses",
            },
            {
              typeName: "email_host",
              icon: "emailhost",
              displayName: "Email Host",
            },
            {
              typeName: "darkrecon_search_query",
              icon: "mdi-spider-thread",
              displayName: "Dark Recon Queries",
            },
            {
              typeName: "domain_of_interest",
              icon: "mdi-crosshairs",
              displayName: "Domain of Interest",
            },
            {
              typeName: "domain_search_term",
              icon: "mdi-search-web",
              displayName: "Domain Search Terms",
            },
          ];

          for (const i of allItems) {
            processCustomerItem(
              pan,
              result.data,
              i.typeName,
              i.icon,
              i.displayName
            );
          }

          context.commit("fillSummaryPanels", pan);
        })
        .catch(function (error) {
          console.debug(error);
        });
      return new Promise((resolve) => {
        console.log(params);
        listMonitoringItems(payload, params)
          .then(function (result) {
            if (result.data.data.length == 0) {
              context.commit("replaceValue", {
                key: "noItems",
                value: true,
              });
            } else {
              context.commit("replaceValue", {
                key: "noItems",
                value: false,
              });
            }
            for (let c = 0; c < result.data.data.length; c++) {
              let item = result.data.data[c];

              item.parent = "";
              item.main_value = item.value.value;
              item.modified = formatDate(item.modified_at);
              if (item.created_from) {
                item.parent = item.created_from.value.value;
              }
            }
            context.commit("fillCustomerItems", result.data.data);
            resolve(result.data);
          })
          .catch(function (error) {
            console.error(error);
            return;
          });
      });
    },
    updateCustomers(context, customer) {
      const params = {
        client_id: customer.client_id,
      };
      let payload;
      if (customer.service == "Active") {
        payload = {
          enabled: true,
          is_trial: false,
          name: customer.name,
          description: customer.description,
        };
      }

      if (customer.service == "Trial") {
        payload = {
          enabled: true,
          is_trial: true,
          name: customer.name,
          description: customer.description,
        };
      }

      if (customer.service == "Disabled") {
        payload = {
          enabled: false,
          name: customer.name,
          description: customer.description,
        };
      }
      updateCustomer(payload, params)
        .then(function (result) {
          console.warn(result);
          context.state.clipboardContent = result.data.msg;
          context.dispatch("getCustomers");
        })
        .catch(function (error) {
          console.error(error);
          alert(error);
          return;
        });

      context.dispatch("getCustomerStats");
    },

    deleteCustomers(context, customer) {
      const params = {
        client_id: customer.client_id,
      };
      const payload = {};
      deleteCustomer(payload, params)
        .then(function (result) {
          context.state.clipboardContent = result.data.msg;
          context.dispatch("getCustomers");
        })
        .catch(function (error) {
          console.error(error);
          alert(error);
          return;
        });
    },
    getCustomerStats(context) {
      customersStatsGet({}, {})
        .then(function (result) {
          context.commit("replaceValue", {
            key: "customerStatsActive",
            value: result.data.data.active.enabled,
          });
          context.commit("replaceValue", {
            key: "customerStatsTrial",
            value: result.data.data.trial.enabled,
          });
          context.commit("replaceValue", {
            key: "customerStatsDisabled",
            value:
              result.data.data.total -
              result.data.data.active.enabled -
              result.data.data.trial.enabled,
          });
        })
        .catch(function (error) {
          console.debug(error);
        });
    },
    createCustomers(context, customer) {
      const payload = {
        name: customer.name,
        description: customer.description,
        renewal_date: moment().add(365, "days").format("YYYY-MM-DD"),
      };
      createCustomer(payload, {})
        .then(function (result) {
          console.warn(result);
          context.state.clipboardContent = result.data.msg;
          context.dispatch("getCustomers");
        })
        .catch(function (error) {
          console.error(error);
          alert(error);
          return;
        });
    },
    getCustomerById(context, customerInfo) {
      return new Promise((resolve) => {
        const payload = {};
        const params = {
          client_id: customerInfo.client_id,
        };
        getCustomer(payload, params)
          .then(function (result) {
            context.state.customerName = result.data.data.name;
            resolve(result.data.data);
          })
          .catch(function (error) {
            console.debug(error);
          });
      });
    },
    getCustomerSummary(context, payload) {
      return new Promise((resolve) => {
        let summaryPayload;
        if (payload.hasOwnProperty("enabled")) {
          summaryPayload = {
            client_id: payload.client_id,
            enabled: payload.enabled,
          };
        } else {
          summaryPayload = { client_id: payload.client_id };
        }
        customerSummary({}, summaryPayload)
          .then(function (result) {
            console.log(result);
            result.data.data.client_id = payload.client_id;
            context.commit("fillCustomerSummary", result.data.data);
            resolve(result.data.data);
          })
          .catch(function (error) {
            console.debug(error);
          });
      });
    },
    getCustomerProgress(context, clientId) {
      return new Promise((resolve) => {
        customerProgress({}, { client_id: clientId })
          .then(function (result) {
            result.data.data.client_id = clientId;
            console.log(result.data.data);
            context.commit("fillCustomerProgress", result.data.data);
            resolve(result.data.data);
          })
          .catch(function (error) {
            console.debug(error);
          });
      });
    },
    getCustomerProgressByType(context, clientId) {
      return new Promise((resolve) => {
        customerProgressItems(
          {},
          { client_id: clientId, page: 1, results_per_page: 10000 }
        )
          .then(function (result) {
            context.commit("fillCustomerReadiness", result.data.data);
          })
          .catch(function (error) {
            console.debug(error);
          });
      });
    },

    getCustomers(context) {
      return new Promise((resolve, reject) => {
        context.dispatch("getCustomerStats");
        const payload = {};
        const params = {
          results_per_page: 3000,
          page: 1,
          is_trial: context.state.is_trial,
          enabled: context.state.customer_enabled,
          user_id: context.state.user_id,
          sort_by: "name",
          sort_order: "asc",
        };

        setPermalinkParams(params);

        for (const param of Object.keys(params)) {
          if (params[param] == "null" || params[param] == null) {
            delete params[param];
          }
        }

        listCustomers(payload, params)
          .then(function (result) {
            context.commit("fillCustomers", result.data.data);
            resolve(result.data.data);
          })
          .catch(function (error) {
            console.debug(error);
          });
      });
    },
  },
  mutations: {
    replaceValue(state, { key, value }) {
      state[key] = value;
    },
    nextPage(state) {
      state.itemsPage += 1;
    },
    firstPage(state) {
      state.itemsPage = 1;
    },
    fillCustomerSummary(state, payload) {
      state.summary = [payload];
    },
    fillSummaryPanels(state, payload) {
      state.summaryPanels = payload;
    },
    fillCustomerProgress(state, payload) {
      state.progress = [payload];
    },
    fillCustomerReadiness(state, payload) {
      state.readiness = payload;
    },
    fillCustomers(state, payload) {
      state.customers = payload;
    },
    fillCustomerItems(state, payload) {
      state.items = payload;
    },
    fillItemTypes(state, payload) {
      state.itemTypes = payload;
    },
    fillResultTypes(state, payload) {
      state.resultTypes = payload;
    },
    fillUsers(state, payload) {
      state.users = payload;
    },
    fillReports(state, payload) {
      state.reports = payload;
    },
    fillReportTypes(state, payload) {
      state.reportTypes = payload;
    },
    clearClipboard(state) {
      state.clipboardContent = null;
    },
    clearState(state) {
      state.customers = [];
      state.itemTypes = [];
      state.resultTypes = [];
      state.users = [];
      state.progress = [];
      state.summary = [];
      state.readiness = [];
      state.items = [];
      state.clipboardContent = null;
      state.itemsPage = 1;
      state.currentItemType = 5;
      state.customerStatsTrial = 0;
      state.customerStatsActive = 0;
      state.customerStatsDisabled = 0;
      state.sortBy = "value";
      state.sortOrder = "asc";
      state.q = "";
    },
  },
};

export default customerList;
